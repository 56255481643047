import Colors from './Colors';

export default {
    cursorPointer: {
        cursor: "pointer",
    },
    cursorAuto: {
        cursor: "auto",
    },
}
