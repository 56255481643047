import React, { Fragment } from 'react';
import moment from "moment";
import DeviceDetector from "device-detector-js";

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Crypto from '../modules/Crypto';
import Validate from '../modules/Validate';
import Analytics from '../modules/Analytics';
import StylesPlatform from '../modules/StylesPlatform';
import Constant from '../modules/Constant';

// Elements
import Div from './Div';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Textarea from '../elements/Textarea';
import Span from '../elements/Span';
import H1 from '../elements/H1';
import H2 from '../elements/H2';
import H3 from '../elements/H3';
import WebappLoginRegister from '../elements/WebappLoginRegister';
import Doc from '../components/Doc';
import RegisterFlow from './RegisterFlow';
import DebugBox from '../elements/DebugBox';
import Dropdown from '../elements/Dropdown';
import MetaMask from '../elements/MetaMask';
import Modal from '../elements/Modal';
import Carousel from '../elements/Carousel';
import PanelQa from '../elements/PanelQa';
import Calendar from '../elements/Calendar';
import AfterpartyLogo from '../elements/AfterpartyLogo';
import Countdown from '../elements/Countdown';
import AudioChip from '../elements/AudioChip';
import Spinner from '../elements/Spinner';
import EmailPhoneVerification from '../elements/EmailPhoneVerification';
import AudioShoutout from '../elements/AudioShoutout';

import StripePayment, { StripeV1, StripeV1SingleButton } from '../elements/StripePayment';
import PaymentsV2, {
    cleanUrlQueryStrings
} from '../elements/PaymentsV2';

import Lottie from "lottie-react-web"

// Components
import ConnectWallet from './ConnectWallet';
import SplashFooter from './SplashFooter';
import PageNotFound from './PageNotFound';
import SplashSocialMediaIcons from './SplashSocialMediaIcons';
import Leaderboard from './Leaderboard';
import SocialLinks from './SocialLinks';

import afterpartyLogo from "../assets/images/splash/logo@3x.png";
import apAiLogo from "../assets/images/apAiLogo.png";
import apAi from "../assets/images/apAi.png";
import envelopeEmail from "../assets/images/envelope.png";

import '../App.css';

export default class Post extends React.Component {
    constructor(props) {
        super(props);
        this.childDiv = React.createRef();
        this.state = {
            pageInfo: null,
            showMoonPayModal: false,
            isLoading: true,
            formErrors: [],
            currentLocation: Utils.get(props, "history.location.pathname"),
            showRegisterFlowModal: false,
            showPayPalSuccessModal: false,
            showPayPalSuccessModal2: false,

            showSampleFormModal: false,

            userDevice: null,

            isLowPowerMode: false,
            stripeInfo: false,
            stripeSelected: false,
            isCreditCard: false,
            isWire: false,

            showRegisterFlowFollowModal: false,

            showRegisterGenericModal: false,

            eventTimeStamp: false,

            showPaymentsV2Modal: false,
            showPaymentsV2SuccessModal: false,
            showPaymentsV2FailureModal: false,
            renderHtml: Utils.getRequest("html") == 17,

            // form10_modalpost_not_top_100: true,

            // form1_weeklyPercentage: "hide", // NOTE hide the weekly percentage when i get it from the backend
        };
        ll.silent = 1; //Utils.getRequest("silent", 1);
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        this.htmlStr = '';

        const stage = Utils.getRequest('stage', 'marketing');
        let tempState = { stage: stage, purchased: false };
        if (Utils.getCookie('purchased')) {
            tempState['purchased'] = Utils.getCookie('purchased');
            tempState['showPayPalSuccessModal2'] = true;
            Utils.deleteCookie('purchased');
            setTimeout(() => {
                const quantity = 0;
                const pricePerTicket = 0;
                const value = quantity * pricePerTicket;

                const eventProperties = {
                    userID: 'afterparty-' + Utils.get(this.props, "user.id", ''), //string minimum of 5 characters
                    value: value,
                    currency: 'USD',
                    quantity: quantity,
                    paymentsource: 'unknown'
                };
                amplitude.track('payment_client_payment_completed', eventProperties);

                const gtmData = {
                    'event': 'ticket_purchase',
                    'purchase.user_id': Utils.get(this.props, "user.id"),
                    'purchase.user_email': Utils.get(this.props, "user.email"),
                    'purchase.user_phone': Utils.get(this.props, "user.primary_phone1"),
                    'onsale_event_id': 'gravy_sep1',
                    'quantity': quantity,
                    'value': quantity * pricePerTicket,
                    'currency': 'USD',
                    'artist': 'yung gravy'
                };
                //console.log("gtmData", gtmData);
                if (window.dataLayer) {
                    window.dataLayer.push(gtmData);
                    console.log("GA ticket", gtmData);
                } else {
                    console.log("GA not loaded in time!");
                }
            }, 500);
        }

        // e.g. /webapp/room/luke_s_afterparty_6_14-3988876182?other=test&payment_intent=pi_3NLWbCJwtuvY8RLr0Yku6pG5&payment_intent_client_secret=pi_3NLWbCJwtuvY8RLr0Yku6pG5_secret_MbhN6Csn9HQLTghrvBqSB2Mih&redirect_status=succeeded&paymentType=tipPayment
        if (Utils.getRequest('payment_intent') && Utils.getRequest('redirect_status')) {
            const redirectStatus = Utils.getRequest('redirect_status')
            console.log('redirect>window.location', window.location, '\n redirectStatus', redirectStatus)

            if (redirectStatus === "succeeded") {
                tempState['showPayPalSuccessModal2'] = true;
                tempState['showPaymentsV2SuccessModal'] = true;
            }

            if (redirectStatus === "failed") {
                tempState['showPaymentsV2FailureModal'] = true;
            }

            // if we need to handle per paymentType uncomment below
            // if (Utils.getRequest('paymentType') === 'collectable') {
            // }
        }

        this.setState(tempState);
        this.loadPost(pr);

        this.scrollToLeaderboard();

        const deviceDetector = new DeviceDetector();
        let userDevice = deviceDetector.parse(window.navigator.userAgent);
        window.lottieLoaded = (dataName, data) => {
            //console.log("!!!!Loaded Lottie", dataName)
            var tempState = {};
            tempState[dataName] = data;
            this.setState(tempState);
        }

        this.setState({
            userDevice: userDevice,
        });
    }

    detectLowPowerMode() {
        let cardVideo = $(".cardVideo video");
        if (cardVideo && cardVideo[0]) {
            cardVideo[0].play()
                .then(() => { })
                .catch((error) => {
                    console.log("error >>>", error);
                    this.setState({
                        isLowPowerMode: true,
                    });
                });
        }
    }

    loadPost(pr) {
        const pathName = pr.pathName ?? Utils.get(pr, 'history.location.pathname');
        let parts = [];
        if (pathName) {
            parts = pathName.split('/');
        } else if (pr.pathName) {
            parts = pathName.split('/');
        }

        if (pr.postId) {
            Api.getShortLinkPost(pr.postId, this.processPageInfo.bind(this), Utils.processPathParams());
        } else if (pathName.substr(0, 3) == "/p/" && parts.length >= 3) {
            let postRef = parts[2];
            let part3 = Utils.get(parts, "3", "");
            if(parts.length >= 4 && part3.substr(0,10) == "artist_id-") {
                var artistIdInfo = part3.substr(10);
                var partsAII = artistIdInfo.split("/");
                postRef += "/artist_id-"+partsAII[0];
            }
            Api.getShortLinkPost(postRef, this.processPageInfo.bind(this), Utils.processPathParams());
        } else {
            const postRef = parts[parts.length - 1];
            Api.getPost(postRef, this.processPageInfo.bind(this), false, Utils.processPathParams());
        }
    }

    componentWillUnmount() {
        if (window && window.moonpayInterval) {
            window.clearInterval(window.moonpayInterval);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newLocation = Utils.get(nextProps, "history.location.pathname");
        if (this.state.currentLocation != newLocation) {
            this.setState({ currentLocation: newLocation });
            this.loadPost(nextProps);
        } else if (nextProps.pathName != this.props.pathName) {
            this.loadPost(nextProps);
        }

        if (nextProps.user) {
            this.setState({ 'field^user': nextProps.user });
        }

        //console.log("componentWillReceiveProps", nextProps);
    }

    closeRegisterGenericModal() {
        this.setState({
            showRegisterGenericModal: false,
        });
    }

    scrollToLeaderboard() {
        if (Utils.getRequest("leaderboard") == "scroll") {
            setTimeout(() => {
                document.getElementById('leaderboard').scrollIntoView({
                    behavior: 'smooth'
                });
                // TODO: remove request param so upon refresh it doesn't scroll
            }, 1500);
        }
    }

    // TODO: Deprecated. Refactor to Api.goto
    goTo(path, target) {
        const formPrefix = "form1_";
        const pr = this.props;
        console.log("path", path);
        path = path.replace("{ad_id}", Utils.get(this.state, "adCode", ""));
        if (window.location.search.length > 0) {
            if (path.indexOf("?") == -1) {
                path += "?";
            }
            const params = "&" + window.location.search.replace('?', '');
            path = path + params;
        }
        //console.log("path", path);
        //return;


        /*
        const reqPrefix = formPrefix + "_req_";
        const reqStrParts = path.split("?");
        console.log(reqStrParts, reqStrParts.length);
        if(reqStrParts.length == 2) {
            console.log("state", this.state);
            const reqStrParams = reqStrParts[1].split("&");
            console.log(reqStrParams, reqStrParams.length);
            for(var idx in reqStrParams) {
                var paramPart = reqStrParams[idx].split("=");
                var paramVar = formPrefix + paramPart[1].replace(/{/, '').replace(/}/, '');
                console.log("paramVar", paramVar);
                var val = '';
                if(paramVar in this.state) {
                    val = "JOE"; this.state[paramVar];
                }
                console.log("replace", paramPart[1],"with", val);
                path = path.replace(paramPart[1], val);
            }
        }
        */

        if (path.indexOf("http") < 0 && path.substr(0, 1) != '/') {
            path = '/' + path;
        }

        if (target) {
            window.open(path, target);
        } else {
            window.location = path;
        }
    }

    processPageInfo(o) {
        const pr = this.props;

        let pathName = Utils.get(pr, 'history.location.pathname') + "";
        const parts = pathName.split('/');
        let adCode = "";
        if (pathName.indexOf("/p/") != -1) {
            // Shortlink
            if (parts.length >= 4) {
                const last = parts[parts.length - 1];
                const lastParts = last.split('?');
                adCode = lastParts[0];
            }
        }
        //console.log("adCode", adCode);
        //console.log("processPageInfo pathName", pathName);
        const postRef = parts[0];

        const pageInfo = Utils.get(o, "data.json_data");
        const pageData = Utils.get(o, "data");
        let tempState = { adCode: adCode, postId: Utils.get(o, "data.id") }
        let desktopPageInfo = null;
        let mobilePageInfo = null;
        let cardsDict = null;
        if (pageInfo) {
            const cards = Utils.get(pageInfo, "cards", []);
            let desktopPageIdx = -1;
            let mobilePageIdx = -1;
            let formDefaults = {};
            // Loop through cards, get form fields, and separate into desktop and mobile
            for (var idx in cards) {
                const card = cards[idx];
                const title = Utils.get(card, 'props.title');
                const name = Utils.get(card, 'props.name');
                const formName = Utils.get(card, "props.form_name", 'form1');
                let field = name;
                if (!Utils.empty(formName)) {
                    field = formName + '_' + name;
                }

                if (card['type'] == "input") {
                    tempState[field] = '';
                    if (Utils.get(card, 'props.required') == 'true') {
                        tempState[field + "^REQUIRED"] = true;
                    }
                    if (Utils.get(card, 'props.minLength') > 0) {
                        tempState[field + "^MIN"] = parseInt(Utils.get(card, 'props.minLength'));
                    }
                    if (Utils.get(card, 'props.maxLength') > 0) {
                        tempState[field + "^MAX"] = parseInt(Utils.get(card, 'props.maxLength'));
                    }
                } else if (card['type'] == "hidden") {
                    const cardName = Utils.get(card, 'props.name');
                    const defaultVal = Utils.get(card, 'props.defaultVal', '');
                    const formName = Utils.get(card, 'props.form_name', '');
                    if (cardName == 'ad_id') {
                        tempState[field] = adCode;
                    } else if (cardName.substr(0, 6) == "field^") {
                        tempState[field] = Utils.get(this.state, cardName, defaultVal);
                    } else if (cardName.substr(0, 4) == "form") {
                        tempState[field] = Utils.get(this.state, cardName, defaultVal);
                    } else if (cardName.substr(0, 4) == "req_") {
                        tempState[field] = Utils.getRequest(cardName.substr(4), defaultVal, false, true);
                    }
                } else if (card['type'] == "parameter") {
                    const props = Utils.get(card, 'props');
                    // param^price_per_ticket=101
                    const prefix = "param^";
                    const initialPrice = Utils.get(o, "data.item.initial_price")
                    for (var idx in props) {
                        if (idx.indexOf(prefix) == 0) {
                            ll._("PARAM", idx, props[idx]);
                            if (idx == "param^price_per_ticket" && initialPrice) {
                                tempState[idx] = initialPrice;
                            } else {
                                tempState[idx] = props[idx];
                            }
                        }
                    }
                } else if (card['type'] == "redirect") {
                    const props = Utils.get(card, 'props');
                    let url = Utils.get(props, "redirect_url");
                    let notLoggedInRedirectUrl = Utils.get(props, "notLoggedInRedirectUrl");
                    const formTakenCount = Utils.get(pageData, "includedData.formTakenCount");
                    if (pr.user.email && pr.userLoaded && notLoggedInRedirectUrl) {
                        console.log("Not logged in, redirect");
                        Utils.gotoRoute(pr, notLoggedInRedirectUrl);
                    } else if (url) {
                        if (formTakenCount > 0) {
                            console.log("Redirect to:", url);
                            if (!Utils.getRequest("test")) {
                                // this.goTo(url);
                                Utils.gotoRoute(pr, url);
                            } else {
                                console.log("Redirect blocked. Test overide");
                            }
                        } else {
                            console.log("Form not taken", formTakenCount);
                        }
                    } else {
                        console.log("ERROR: No redirect set!");
                    }
                } else if (card['type'] == "field") {
                    console.log("FIELD", card);
                    const props = Utils.get(card, 'props');
                    // param^price_per_ticket=101
                    const prefix = "field^";
                    if (props['name'].indexOf(prefix) == 0) {
                        console.log("FIELDFOUND", idx, props[idx], props['name'], props['description']);
                        tempState[props['name']] = props['description'];
                    }
                } else if (card['type'] == "checkbox") {
                    //console.log("CHECKBOX initiate", card);
                    const props = Utils.get(card, 'props');
                    // param^price_per_ticket=101
                    const formName = Utils.get(props, "form_name", 'form1');
                    const prefix = "field^";
                    if (props['name'].indexOf(prefix) == 0) {
                        //console.log("CHECKBOXFOUND setup initial state", idx, props[idx], props['description']);
                        tempState[formName + '_' + props['name']] = props['description'] == "true" ? true : false;
                    }
                } else if (card['type'] == "paypal") {

                    /*
                    TODO: Test and remove references from index.html
                    var env = "sandbox";
                    if(env == "live") {
                        $('<script src="https://www.paypal.com/sdk/js?client-id=AZvYe5TMNxcPrklPrgLjT88vZ33Gp69pqAQCfFmdWbqZ-nOwOgEfrNujlpcyMfiePmqhd5eDICWEdzpI&currency=USD"></script>').appendTo("head");
                    } else {
                        $('<script src="https://www.paypal.com/sdk/js?client-id=AUBR5K5ZCXR7VNCLlHJA_TBRv4wd9-7u2gLfOtxvusjhRauBvHdHN5Mwy0IB9CVsWBt40lAIZWqGc0TD&currency=USD"></script>').appendTo("head");
                    }
                    */

                } else if (card['type'] == "panelqa") {
                    Utils.set(card, "props.enabled", "false");
                } else if (card['type'] == "countdown") {
                    const event = Utils.get(o, "data.event");
                    const props = Utils.get(card, 'props');
                    const name = Utils.get(props, 'name', "countdown_timer");
                    const eventSecondsLeft = parseInt(Utils.get(event, "GoLive.secondsLeft"));
                    const eventMinutesLeft = Utils.get(event, "GoLive.minutesLeft");
                    const eventStatus = Utils.get(event, "status");

                    const currentTimeStamp = parseInt(Date.now() / 1000);
                    const eventTimeStamp = currentTimeStamp + eventSecondsLeft;

                    this.setState({
                        eventTimeStamp: eventTimeStamp,
                    });
                } else if (card['type'] == "lottie") {
                    let src = Utils.get(card, "props.src");
                    if(src.indexOf(".js") != -1) {
                        // Add script tag
                        //console.log("Lottie Add script tag");
                        var js = window.document.createElement("script");
                        js.src = src;
                        window.document.body.appendChild(js);
                    }
                }

                if (title && title.substr(0, 17) == 'container_desktop') {
                    desktopPageIdx = idx;
                    continue;
                } else if (title && title.substr(0, 16) == 'container_mobile') {
                    mobilePageIdx = idx;
                    continue;
                }
            }
            cardsDict = Utils.arrayToObject(cards, "id");
            if (desktopPageIdx != -1 && mobilePageIdx != -1) {
                // Check if desktop comes before or after mobile
                if (desktopPageIdx < mobilePageIdx) {
                    desktopPageInfo = cards.slice(desktopPageIdx, mobilePageIdx);
                    mobilePageInfo = cards.slice(mobilePageIdx);
                } else {
                    desktopPageInfo = cards.slice(desktopPageIdx);
                    mobilePageInfo = cards.slice(mobilePageIdx, desktopPageIdx);
                }
            }
            //console.log("desktopPageInfo", desktopPageInfo);
            //console.log("mobilePageInfo", mobilePageInfo);
        }

        tempState['pageInfo'] = pageInfo;
        // TODO: remove cardsDict completely from state, and make references pull from pageInfo.cards
        tempState['cardsDict'] = cardsDict;
        tempState['desktopCards'] = desktopPageInfo;
        tempState['mobileCards'] = mobilePageInfo;
        tempState['moonpayInfo'] = Utils.get(o, "includes.moonpay");
        tempState['leaderboard'] = Utils.get(o, "data.leaderboard");
        tempState['field^leaderboard'] = Utils.get(o, "data.leaderboard");
        tempState['instagallery'] = Utils.get(o, "data.instagallery");
        tempState['field^instagallery'] = tempState['instagallery'];
        tempState['instaconfig'] = Utils.get(o, "data.instaconfig");
        tempState['instaitem'] = Utils.get(o, "data.instaitem");
        tempState['injectedPosts'] = Utils.get(o, "data.injectedPosts", {});
        tempState['includedPosts'] = Utils.get(o, "data.includedPosts", {});
        tempState['field^stats'] = Utils.get(o, "data.stats");
        tempState['field^item'] = Utils.get(o, "data.item");
        tempState['field^items'] = Utils.get(o, "data.items");
        tempState['field^collection'] = Utils.get(o, "data.collection");
        tempState['field^event'] = Utils.get(o, "data.event");
        tempState['field^user'] = Utils.get(this.props, "user");

        if (tempState['field^event']) {
            const eventState = Utils.get(tempState['field^event'], "status");
            switch (eventState) {
                case 6: // NOTE: saleActive
                    tempState['form1_eventState'] = "saleActive";
                    break;
                case 7: // NOTE: saleLivestreamInProgress
                    tempState['form1_eventState'] = "saleLivestreamInProgress";
                    break;
                default: // NOTE: case: 5, which is saleInactive
                    tempState['form1_eventState'] = "saleInactive";
            }
        }

        // This will select the default item on the Collection Purchase page
        tempState['form1_collectible_container'] = Utils.getRequest("collectible", undefined, false, true);

        // If there is a collection, but no collectible specified, select the first one
        if (tempState['field^collection'] && !tempState['form1_collectible_container']) {
            tempState['form1_collectible_container'] = Utils.get(tempState['field^items'], "0.id");
        }

        tempState['field^artists'] = Utils.get(o, "data.artists", []);
        tempState['field^artistsDict'] = Utils.arrayToObject(tempState['field^artists'], "id");

        const artist = Utils.get(o, "data.artist");
        if (artist) {
            tempState['field^artist'] = artist;
            tempState['field^artist_customer'] = Utils.get(o, "data.artist_customer", {});
            tempState['field^artist_id'] = Utils.get(artist, "id");
            tempState['field^artist_image_url'] = Utils.get(artist, "image_url");
            tempState['field^artist_name'] = Utils.get(artist, "name");
            tempState['field^artist_is_following'] = "no";
            tempState['field^customer_is_following'] = Utils.get(artist, "customer_is_following");
            this.setState({
                customerIsFollowing: Utils.get(artist, "customer_is_following"),
            });
            tempState['form1_tabbedSelected'] = "option1";
        }
        tempState['docs'] = Utils.get(o, "data.docs");
        tempState['isLoading'] = false;
        this.setState(tempState);

        // this.detectLowPowerMode();
    }

    handleSetVal(name, value) {
        let tempState = {};
        tempState[name] = value;
        this.setState(tempState);
    }

    /**
     * Used for:
     * - StripePayment
     * - renderCard_calculated
     * @param {boolean} fixed determines the type returned
     * @returns float or int e.g. 25.00 or 2500
     */
    setCalculatedValue(fixed = true) {
        const quantity = Utils.get(this.state, "param_ticket_quantity", 1);
        let pricePerTicket = this.state["param^price_per_ticket"];
        if (Utils.get(this.state, "instaitem.unitPrice")) {
            pricePerTicket = Utils.get(this.state, "instaitem.unitPrice");
        }
        return (quantity * pricePerTicket).toFixed(2)
    }

    triggerStripeIntent(soldOut, hasStripeRef, selectedItem) {
        if (!soldOut && hasStripeRef) {
            this.toggleCreditCardOption(selectedItem);
        }
    }

    toggleCreditCardOption(selectedItem) {
        const pr = this.props;
        const st = this.state;

        // Note: not sure why this reverts the credit card but causes a stripe payment
        // intent to not fire which does not update the amount
        // const itWillBeCreditCard = !st.isCreditCard;
        const itWillBeCreditCard = true
        const quantity = Utils.get(st, "param_ticket_quantity", 1);
        const qty = st.selectedQuantity ? st.selectedQuantity : quantity;

        console.log('>>>>>>>>toggleCreditCardOption>st', st, '\n props', pr,
            '\n st.selectedQuantity.value >>>', qty,
            '\n itWillBeCreditCard', itWillBeCreditCard
        )

        const collectibleId = selectedItem ? selectedItem.id : null;
        if (itWillBeCreditCard) {
            Api.getStripeIntent(qty, (o) => {
                if (o && o.success) {
                    this.setState({
                        isCreditCard: itWillBeCreditCard,
                        isWire: false,
                        stripeInfo: o.data,
                    });
                } else {
                    console.log("ERRORS: ", o.errors);
                    if (o && o.errors.length > 0) {
                        this.setState({
                            isCreditCard: itWillBeCreditCard,
                            stripeError: "Apple Pay & Google Pay not available at this time. Try again soon.",
                        });
                    }
                }
            }, collectibleId);
        }
    }

    handleSetRadioValue(field, value, itemId) {
        //console.log("RDATA", itemId);
        value = Utils.parameterSubstitute(value, this.state, "");
        value = Utils.parameterSubstitute(value, this.state, "", /{((form[0-9]+_|field\^)[^}]+)}/g);
        this.handleSetVal(field, value);
        for (var idx in this.state['field^items']) {
            var item = this.state['field^items'][idx];
            if (item['id'] == itemId) {
                //console.log("RDATAFOUND", item);
                this.setState({ "param^price_per_ticket": Utils.get(item, 'initial_price', 7.00) });
                break;
            }
        }
    }
    handleToggleRadioValue(field, value, itemId) {
        console.log("handleToggleRadioValue +++++", field, value, itemId);
        //console.log("RDATA", itemId);
        if (this.state[field] == value) {
            this.handleSetVal(field, "");
        } else {
            this.handleSetVal(field, value);
        }
    }

    checkMoonpayComplete(contractAddress, tokenId, walletId) {
        // /api/v1/moonpay/completed_tx/0x9f9b8aBC6F948b49522e7a4B17b58F9185D4d098/1/0x4a38E4959aA401Dc706aE7568f3253B6cA15CC9A
        Api.getMoonpayComplete(contractAddress, tokenId, walletId, (o) => {
            const listingId = Utils.get(o, 'data.listing_id');
            console.log("getMoonpayComplete", listingId);
            if (listingId) {
                this.setState({ listingId: listingId, showMoonPayListingModal: true, showMoonPayModal: false });
                if (window.moonpayInterval) {
                    window.clearInterval(window.moonpayInterval);
                }
            }

        });
    }

    handleMoonpayClick() {
        const contractAddress = Utils.get(this.state, "moonpayInfo.contractAddress"); //"0xA1A9dFab98C5F692119686Aff5f5Bd993A8B5330";
        const tokenId = Utils.get(this.state, "moonpayInfo.tokenId"); //"112457658586931934152042554105038651850087300550240488280277587652331018649601";
        const walletId = Utils.get(this.state, "moonpayInfo.walletId"); //"IL%2BSQal4xSAbjGc4vqkUX6mRaqLSkaqhWHodeaglCaE%3D";
        if (window.moonpayInterval) {
            window.clearInterval(window.moonpayInterval);
        }
        window.moonpayInterval = window.setInterval(this.checkMoonpayComplete.bind(this, contractAddress, tokenId, walletId), 10 * 1000);

        this.setState({ showMoonPayModal: true });
    }

    handleBuyPayPalClick(cardId, soldOut, hasStripeRef, selectedItem) {
        if (Utils.get(this.props, "user.id")) {
            console.log("Show paypal for card id", cardId);
            if (!soldOut && hasStripeRef) {
                this.toggleCreditCardOption(selectedItem);
            }
            this.setState({
                paypalCardId: cardId,
            }, function () {
                this.showModal('showPayPalModal');
            });
        } else {
            this.setState({ paypalCardId: cardId }, function () {
                this.showModal('showRegisterFlowModal');
            });
        }
    }

    handleFollowClick(artistId, followType, cardId) {
        const pr = this.props;
        console.log("handleFollowClick user: >>>>", Utils.get(pr, "user"));
        if (Utils.get(pr, "user.id")) {
            const modalData = {
                artist_id: artistId,
                follow_type: Constant.FOLLOW_TOGGLE,
                from_customer_id: pr.user.id
            };
            // If this is a Goto URL type and user is already following, then goto now
            if (followType == 3 && this.state.customerIsFollowing) {
                const url = "/p/shoutouts_form/artist_id-" + artistId; // POST: 215
                Utils.gotoRoute(pr, url);
                return;
            }

            Api.postFollow(modalData, (res) => {
                if (!Api.noErrors(res)) {
                    console.log("ERROR: FOLLOW DIDN'T WORK");
                } else {
                    this.setState({
                        customerIsFollowing: Utils.get(res, "data.follow"),
                    });

                    Api.apiLoadProfile(pr.setUser.bind(this));
                }
                // Follow type == 2 forces reload in case of login->follow->check fillout count
                if (followType == 2) {
                    window.location.reload();
                } else if (followType == 3) { // Go to new page after follow
                    const url = "/p/shoutouts_form/artist_id-" + artistId; // POST: 215
                    Utils.gotoRoute(pr, url);
                    return;
                }
            }); // NOTE toggle Api call
        } else {
            this.setState({ artistIdToFollow: artistId }, () => {
                this.showModal(`showRegisterFlowFollowModal_${cardId}`);
            });
        }
    }

    getFormVariables(formId, logErrors) {
        let stateClear = {};
        const formPrefix = "form" + formId + "_";
        const fieldPrefix = formPrefix + "field^";
        //console.log("Getting fields for form: "+formPrefix);
        const formPrefixLen = formPrefix.length;
        let data = {};
        let success = true;
        let formErrors = [];

        for (var key in this.state) {
            // Check if key for field exists. For example: form1_first_name
            //console.log(key.substr(0, formPrefixLen), formPrefix, key.indexOf('^') == -1);
            if (key.substr(0, formPrefixLen) == formPrefix) {
                // If it has a caret, but it's not a field^, then skip
                if (key.indexOf('^') != -1 && key.indexOf(fieldPrefix) == -1) {
                    continue;
                }
                // Reset value of that field after posted to DB
                stateClear[key] = '';
                //console.log("set", key.substr(formPrefixLen), Utils.get(this.state, key, ''));
                let fieldVal;
                if (key.indexOf(fieldPrefix) == -1) {
                    fieldVal = Utils.get(this.state, key, '');
                } else {
                    const fieldKey = key.replace(formPrefix, '');
                    //console.log("FIELDKEY", fieldKey);
                    fieldVal = Utils.get(this.state, fieldKey, '');
                }
                //console.log("getFormVariables", key, fieldVal);
                data[key.substr(formPrefixLen)] = fieldVal;
                // If field is required
                const required = Utils.get(this.state, key + "^REQUIRED", "false");
                const minLength = Utils.get(this.state, key + "^MIN", -1);
                const maxLength = Utils.get(this.state, key + "^MAX", -1);
                if (required == true) {
                    if (minLength > 0 && fieldVal.length < minLength) {
                        success = false;
                        formErrors.push("Field '" + key.substr(formPrefixLen).replace(/_/g, " ") + "' must be at least " + minLength + " characters");
                    }
                    if (maxLength > 0 && fieldVal.length > maxLength) {
                        success = false;
                        formErrors.push("Field '" + key.substr(formPrefixLen).replace(/_/g, " ") + "' must be less than " + maxLength + " characters");
                    }
                    if (fieldVal.length == 0) {
                        //console.log("required",  required, required == true, key);
                        success = false;
                        formErrors.push("Required field '" + key.substr(formPrefixLen).replace(/_/g, " ") + "' is empty");
                    }
                }
            }
        }
        if (!success) {
            // alert(JSON.stringify(formErrors));
            if (!logErrors) {
                this.setState({ formErrors: formErrors });
            }
            ll._("formErrors", "red", formErrors);
        }
        data['success'] = success;
        return data;
    }

    handleSubmitCreatorMessage() {
        const creatorMessage = this.state.creatorMessage;
        const jsonData = {
            customerId: Utils.get(this.props, "user.id"),
            creatorMessage: creatorMessage,
            collectible: this.state['form1_collectible_container']
        };
        const row = {
            form_id: 6,
            json: JSON.stringify(jsonData),
        };
        Api.postFormDataRow(row, (o) => {
            //console.log(o);
            let stateClear = {};
            stateClear['creatorMessage'] = "";
            stateClear['showPayPalSuccessModal2'] = false;
            stateClear['successTitle'] = "Posted!";
            stateClear['successMessage'] = "Posted!";
            //stateClear['successRedirectUrl'] = successRedirectUrl;
            this.setState(stateClear);
            this.goTo("mywallet")
        });
    }

    handleFormSubmit(formId, successMessage, successTitle, successRedirectUrl, gaSubmitEvent, url, apiErrorStateVarName) {
        const pr = this.props;

        let stateClear = {};
        const formPrefix = "form1_";
        const formPrefixLen = formPrefix.length;
        let formErrors = [];
        //const formId = 1;
        formId = formId ? formId : 1;

        let data = this.getFormVariables(formId);
        console.log("formData", data, url);
        let success = data['success'];
        delete data['success'];

        if (success) {
            //console.log(data);
            try {
                const gaData = { 'event': gaSubmitEvent, 'submission.user_email': Utils.get(data, "Email", ''), 'submission.user_phone': Utils.get(data, "Phone number", '') };
                //console.log("gaData", gaData);
                window.dataLayer.push(gaData);
            } catch (err) {

            }

            const row = {
                form_id: formId,
                json: JSON.stringify(data),
            }
            if (url && url.length > 0) {
                url += document.location.search;
                Api.postFormUrl(url, row, (o) => {
                    const hideErrorDisplay = !Utils.empty(apiErrorStateVarName);
                    if (Api.noErrors(o, hideErrorDisplay)) {
                        // NOTE: if no errors are present in response
                        let redirectUrl = successRedirectUrl + document.location.search;
                        if (!Utils.empty(Utils.get(o, "data.successRedirectUrl"))) {
                            redirectUrl = Utils.get(o, "data.successRedirectUrl");
                        }
                        Utils.gotoRoute(this.props, redirectUrl);
                    } else {
                        // NOTE: if there are errors, do something
                        if (hideErrorDisplay) {
                            let tempState = this.state;
                            tempState[apiErrorStateVarName] = true;
                            this.setState(tempState);
                        }
                    }
                });
            } else {
                if (Utils.getRequest('testform')) {
                    alert(JSON.stringify(row));
                    return;
                }
                Api.postFormDataRow(row, (o) => {
                    //console.log(postFormDataRow::res:: o);
                    stateClear['formErrors'] = [];
                    stateClear['showFormModal'] = true;
                    stateClear['successTitle'] = successTitle;
                    stateClear['successMessage'] = successMessage;
                    stateClear['successRedirectUrl'] = successRedirectUrl;
                    this.setState(stateClear);

                    if (pr.postApiCallClickHandler) {
                        pr.postApiCallClickHandler(o);
                    }
                });
            }
        }
    }

    showModal(modalVar) {
        const pr = this.props;
        const st = this.state;

        const CREATE_PAYMENT_URL = '/chorus/paypal_payment';
        const EXECUTE_PAYMENT_URL = '/chorus/paypal_execute/';
        let tempState = {};
        tempState[modalVar] = true;
        var _self = this;
        this.setState(tempState, () => {
            if (modalVar == 'showPayPalModal') {
                //console.log(this.state);
                const item = this.getSelectedItem();

                const quantity = Utils.get(this.state, "param_ticket_quantity", 1);
                let pricePerTicket = this.state["param^price_per_ticket"];
                if (Utils.get(this.state, "instaitem.unitPrice")) {
                    pricePerTicket = Utils.get(this.state, "instaitem.unitPrice");
                }
                let userSelections = this.getFormVariables(1);
                userSelections['pricePerTicket'] = Utils.get(item, 'initial_price', 100.00);
                userSelections['quantity'] = quantity;
                userSelections['uid'] = Utils.getRequest('uid', '');
                userSelections['igid'] = Utils.getRequest('igid', '');
                userSelections['collectible'] = Utils.get(item, 'id'); //Utils.getRequest('collectible', '');
                console.log("userSelections", userSelections);
                setTimeout(() => {
                    paypal.Buttons({
                        // Sets up the transaction when a payment button is clicked
                        createOrder: (data, actions) => {
                            return fetch(CREATE_PAYMENT_URL, {
                                method: 'post',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(userSelections)
                            }).then(function (res) {
                                return res.json();
                            }).then(function (data) {
                                return data.orderID;
                            })
                        }
                        ,
                        // Finalize the transaction after payer approval
                        onApprove: (data, actions) => {
                            return actions.order.capture().then(function (orderData) {
                                return fetch(EXECUTE_PAYMENT_URL + data.orderID, {
                                    method: 'post',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(orderData)
                                }).then(function (res) {
                                    return res.json();
                                }).then(function (details) {
                                    console.log("details", details);
                                    _self.setState({ showPayPalModal: false, showPayPalSuccessModal2: true, form1_show_modal: "" });
                                    const value = quantity * pricePerTicket;
                                    // TODO: onsale_event_id : ARTISTNAME, artist_name -- double check qty and value
                                    // event_type: 'afterparty_livestream' <-- paypal card default
                                    const eventProperties = {
                                        userID: 'afterparty-' + Utils.get(pr, "user.id", ''), //string minimum of 5 characters
                                        value: value,
                                        currency: 'USD',
                                        quantity: quantity,
                                        paymentsource: 'paypal'
                                    };
                                    amplitude.track('payment_client_payment_completed', eventProperties);

                                    const gtmData = {
                                        'event': 'ticket_purchase',
                                        'purchase.user_id': Utils.get(pr, "user.id"),
                                        'purchase.user_email': Utils.get(pr, "user.email"),
                                        'purchase.user_phone': Utils.get(pr, "user.primary_phone1"),
                                        'onsale_event_id': 'gravy_sep1',
                                        'quantity': quantity,
                                        'value': quantity * pricePerTicket,
                                        'currency': 'USD',
                                        'artist': 'yung gravy'
                                    };
                                    //console.log("gtmData", gtmData);
                                    window.dataLayer.push(gtmData);
                                });
                            })
                        }
                    }).render('#paypal-button-container-' + this.state['paypalCardId']);
                }, 500)
            }

        });

    }

    closeModal(modalVar) {
        let tempState = {};
        tempState[modalVar] = false;
        this.setState(tempState);
    }


    closeMoonpayModal(modalVar) {
        if (window.moonpayInterval) {
            window.clearInterval(window.moonpayInterval);
        }
        let tempState = {};
        tempState[modalVar] = false;

        this.setState(tempState);
    }

    setCdnUrl(url) {
        if (url) {
            return url.replace("chorusuploads.s3.us-west-1.amazonaws.com", "dhwk6gixvyfl3.cloudfront.net");
        } else {
            return url;
        }
    }

    changeVal(valPath, event) {
        let tempState = {};
        //Utils.set(tempState, valPath, event.target.value);
        const val = Utils.get(event, "target.value");
        tempState[valPath] = val;
        //console.log("EVENT", valPath, event, val, tempState);
        this.setState(tempState);
    }

    changeCheckVal(valPath, event) {
        let tempState = {};
        //Utils.set(tempState, valPath, event.target.value);
        const val = Utils.get(this.state, valPath);
        tempState[valPath] = !val;
        console.log("EVENT", valPath, event, val, tempState);
        this.setState(tempState);
    }

    getCss(data, cssName, defaultCss) {
        let cardStyle = Utils.get(data, "props.style", defaultCss ? defaultCss : { width: "100%" });
        if (!cssName) { // NOTE this is the name of the style from Styles.js module
            let apStyle = Utils.get(data, "props.apStyle", "");
            if (apStyle.length > 0 && apStyle in Styles) {
                cardStyle = Utils.deepCopy(Styles[apStyle]);
                ll._("apStyle Found: " + apStyle, cardStyle);
            } else if (apStyle.length > 0) {
                ll._("Error: apStyle Not Found: " + apStyle);
            }
        } else {
            ll._("apStyle Using CSS name");
        }

        cssName = cssName ? cssName : "css";
        const css = Utils.get(data, "props." + cssName);
        if (typeof css == 'object') {
            // TODO: if indexOf("-"), replace next character with uppercase and remove "-"
            for (var cssIdx in css) {
                if (cssIdx.indexOf('-') != -1) {
                    ll._("FOUND", "red");
                    // cssIdx = Utils.toCamelCase(cssIdx, "-");
                }

                cardStyle[cssIdx] = css[cssIdx];
            }
        }
        if (cssName == 'css' && this.props.screen.mobile) {
            let cssMobile = Utils.get(data, "props.cssMobile");
            if (typeof cssMobile == "string") {
                if (cssMobile.length == 0) {
                    cssMobile = {};
                } else {
                    cssMobile = JSON.parse(cssMobile);
                }
            }
            for (var cssMobileIdx in cssMobile) {
                if (cssMobileIdx.indexOf('-') != -1) {
                    ll._("FOUND", "red");
                    // cssMobileIdx = Utils.toCamelCase(cssMobileIdx, "-");
                }

                cardStyle[cssMobileIdx] = cssMobile[cssMobileIdx];
            }
        }
        if (Utils.get(data, "props.cssSelected")) {
            const num = 1;
            const name = Utils.get(data, "props.name");
            const formName = Utils.get(data, "props.form_name", 'form1');
            let field = name;
            if (!Utils.empty(formName)) {
                field = formName + '_' + name;
            }
            const value = Utils.get(data, "props.value");
            //console.log("FOUND cssSelected", field, Utils.get(this.state, field), value);
            const fieldVal = Utils.get(this.state, field);
            let selected = false;
            if (value == 'NULL') {
                if (Utils.empty(fieldVal)) {
                    selected = true;
                } else {
                    //console.log("NULL is false");
                }
            } else if (value == 'NOTNULL') {
                if (!Utils.empty(fieldVal)) {
                    //console.log("NOTNULL is true", field);
                    selected = true;
                } else {
                    //console.log("NOTNULL is false", field, Utils.get(this.state, "leaderboard"));
                }
            } else if (fieldVal == value) {
                selected = true;
            }

            if (selected) {
                let selCss = Utils.get(data, "props.cssSelected");
                //console.log("cssSelected", selCss);
                if (selCss) {
                    if (typeof selCss == "string") {
                        selCss = JSON.parse(selCss);
                    }
                    cardStyle = $.extend(cardStyle, selCss);
                }
            }
        }
        return cardStyle;
    }

    logStack(stack) {
        let outStr = "";
        for (var idx in stack) {
            outStr += idx + ":" + stack[idx].length + " ";
        }
        ll._(outStr);
    }
    onLoginPhone(res) { }

    onLoginEmail(option, refresh, res) {
        // NOTE: option is currently unused from old code, but i don't want to mess up
        // the parameter order / count, leaving there if we need the variable in the future
        if (Utils.get(res, "errors", []).length) {
            return;
        }
        this.closeRegisterFlowModal();
        if (refresh) {
            window.location.reload();
        } else {
            this.showModal("showPayPalModal");
        }
    }

    onLoginEmailFollow(callback, cardId, res) {
        if (Utils.get(res, "errors", []).length) {
            return;
        }
        this.closeRegisterFlowModal(cardId);

        if (callback) {
            callback();
        }
    }

    onSignUpPhone(res) { }

    onConfirmVerificationCodeLogin(callback, res) {
        console.log("onConfirmVerificationCodeLogin");
        if (Utils.get(res, "errors", []).length) {
            ll._("ERROR", "red", Utils.get(res, "errors", []));
            return;
        }

        this.closeRegisterFlowModal();
        this.showModal("showPayPalModal");
    }

    onConfirmVerificationCodeLoginFollow(callback, cardId, res) {
        console.log("onConfirmVerificationCodeLoginFollow");
        if (Utils.get(res, "errors", []).length) {
            ll._("ERROR", "red", Utils.get(res, "errors", []));
            return;
        }

        this.closeRegisterFlowModal(cardId);
        if (callback) {
            callback();
        }
    }

    onConfirmVerificationCodeSignUp(res) {
        console.log("onConfirmVerificationCodeSignUp");
        if (Utils.get(res, "errors", []).length) {
            ll._("ERROR", "red", Utils.get(res, "errors", []));
            return;
        }

        this.closeRegisterFlowModal();
        this.showModal("showPayPalModal");
    }

    onConfirmVerificationCodeSignUpFollow(callback, cardId, res) {
        console.log("onConfirmVerificationCodeSignUpFollow");
        if (Utils.get(res, "errors", []).length) {
            ll._("ERROR", "red", Utils.get(res, "errors", []));
            return;
        }

        this.closeRegisterFlowModal(cardId);

        if (callback) {
            callback();
        }
    }

    closeRegisterFlowModal(cardId) {
        let tempState = {};
        tempState["showRegisterFlowModal"] = false;

        // NOTE: this handles having more than one Follow Card on a page
        tempState[`showRegisterFlowFollowModal_${cardId}`] = false;

        this.setState(tempState);
    }

    showPayPalModal() {
        this.setState({ showPayPalModal: true })
    }

    plainTextToRichTextArray(text, linkColor, key) {
        if (!linkColor) {
            linkColor = Colors.magenta;
        }

        const regex = /\[[^\]]*\]/g

        if (Utils.empty(text)) {
            return [<Span key={`text-span1-empty-${key}`}></Span>];
        }

        text = text + "";
        // NOTE: test this code; i dont think it works, we need \^ because ^ is a keycharacter in regex
        text = text.replace(/{field^artist.title}/g, Utils.get(this.state, "field^artist.title"));

        // Setup text array to render embedded links and line breaks
        let textArray = [];
        let pos = 0;
        for (var i = 0; i < 100; i++) {
            // Regex match any embedded links
            var match = regex.exec(text);
            if (match) {
                const matchStr = match[0];
                const matchPos = match['index'];
                // Handle the text before the link in case there are line breaks
                const lineBefore = text.substring(pos, matchPos);
                const shortLines = lineBefore.split("\n");
                // If there are line breaks push the lines with a line break between them
                for (var idx in shortLines) {
                    const shortLine = shortLines[idx];
                    if (idx > 0) {
                        // textArray.push(<br key={Utils.uniqueKey("breakcardmatch", shortLine)}/>)
                        textArray.push(<br key={`text-br-${key}-${i}-${idx}`} />)
                    }
                    // textArray.push(<Span key={Utils.uniqueKey("textcardmatch", shortLine)}>{shortLine}</Span>);
                    textArray.push(<Span key={`text-span1-${key}-${i}-${idx}`}>{shortLine}</Span>);
                }
                // Parse the embedded link. Example: [https://google.com|Google Website|_blank]
                const parts = matchStr.replace('[', '').replace(']', '').split('|');
                let link = parts[0].replace("{field^artist.id}", Utils.get(this.state, "field^artist.id"));
                link = link.replace("{field^artist.title}", Utils.get(this.state, "field^artist.title"));
                const title = parts.length > 1 ? parts[1] : link;
                const target = parts.length > 2 ? parts[2] : null;
                // textArray.push(<a style={{color: linkColor}} href={link} target={target} key={Utils.uniqueKey("linkcard", shortLines[idx])}>{title}</a>);
                link = link.replace("{val1}", Utils.get(this.state, "form1_my_field_1"));
                textArray.push(<a key={`text-a-${key}-${i}-${idx}`} style={{ color: linkColor }} href={link} target={target}>{title}</a>);
                pos = matchPos + matchStr.length;
            } else {
                // Any remaining non-processed text
                const lineBefore = text.substring(pos);
                const shortLines = lineBefore.split("\n");
                for (var idx in shortLines) {
                    let shortLine = shortLines[idx];
                    if (idx > 0) {
                        // textArray.push(<br key={Utils.uniqueKey("breakcard", shortLine)}/>);
                        textArray.push(<br key={`text-br2-${key}-${i}-${idx}`} />);
                    }
                    shortLine = shortLine.replace("{field^artist.title}", Utils.get(this.state, "field^artist.title"));
                    // textArray.push(<Span key={Utils.uniqueKey("textcard", shortLine)}>{shortLine}</Span>);
                    textArray.push(<Span key={`text-span2-${key}-${i}-${idx}`}>{shortLine}</Span>);
                }
                break;
            }
        }

        return textArray;
    }
    toggleInstagramItem(checkboxStateName) {
        let state = this.state;

        if (state[checkboxStateName] == undefined) {
            state[checkboxStateName] = false;
        }

        state[checkboxStateName] = !state[checkboxStateName];

        this.setState(state);
    }

    getIgDate(instagalleryItem) {
        let timestamp = Utils.get(instagalleryItem, 'posted_at');

        if (!timestamp) {
            return null;
        }

        timestamp = timestamp.split("+")[0];
        timestamp = timestamp.split("T").join("-");

        return moment(timestamp, "YYYY-MM-DD-hh:mm:ss");
    }

    viewLeaderboard() {
        this.goTo("p/digital_collectible?leaderboard=scroll/collectible-" + tempState['form1_collectible_container']);
    }

    itemClick(item, index) {
        this.setState({
            selectedItem: item,
            selectedItemIndex: index,
            showSampleFormModal: true,
        });
    }

    onRequestCloseSampleFormModal() {
        this.setState({
            showSampleFormModal: false,
        });
    }

    toggleQaPanel(group) {
        const st = this.state;

        const analyticsData = {
            group: group,
        }

        Analytics.trigger("faq_topic_open", analyticsData);

        let pageInfo = Utils.deepCopy(st.pageInfo);
        let cards = Utils.get(pageInfo, "cards");

        // NOTE: iterate through the cards, expand or close panel that is selected
        for (var idx in cards) {
            let card = cards[idx];

            if (Utils.get(card, "type") == "panelqa" && Utils.get(card, "props.group") == group) {
                if (Utils.get(card, "props.enabled") == "true") {
                    Utils.set(card, "props.enabled", "false");
                } else {
                    Utils.set(card, "props.enabled", "true");
                }
            }
        }

        // NOTE: save the cards to the state
        pageInfo.cards = cards;
        this.setState({
            pageInfo: pageInfo,
        });
    }

    openClosePanelQaModal(qaReference, isShowModal) {
        let tempState = {};
        tempState[qaReference] = isShowModal;
        if (isShowModal) {
            let analyticsData = {
                qaReference: qaReference,
            };
            Analytics.trigger("faq_info_open", analyticsData);
        }

        this.setState(tempState);
    }

    postClickHandler(target, value) {
        console.log("postClickHandler CLICK", target, value);
        if (target == 'showArtistFollowModal') {
            if (Utils.get(this.props.user, 'id')) {
                const artistId = Utils.get(this.state, "field^artist.id");
                const modalData = {
                    artist_id: artistId,
                    follow_type: Constant.FOLLOW_TOGGLE,
                    from_customer_id: Utils.get(this.props, "user.id"),
                    value: true,
                };

                Api.postFollow(modalData, (res) => {
                    if (!Api.noErrors(res)) {
                        console.log("ERROR: FOLLOW DIDN'T WORK");
                    } else {
                        this.setState({ form1_modalpost: true, form1_artistPurchaseV3State: false })
                    }
                }); // NOTE toggle Api call
            } else {
                // alert("\nYou must be logged in to receive notifications.\n\nPlease login using the menu on the top right to receive notifications.");
                this.setState({
                    showRegisterGenericModal: true,
                });
            }

        } else {
            console.log("Missing postClickHandler for target " + target);
        }
    }

    renderFormErrors(key, formErrors) {
        formErrors = formErrors ?? this.state.formErrors;
        if (Utils.empty(formErrors)) {
            return null;
        }

        return (
            <Div className="formErrors">
                {formErrors.map((formError, formErrorIndex) => {
                    return (
                        <Div style={{ color: "red", marginTop: 6, marginBottom: 6 }} key={`formerrors-${key}-${formErrorIndex}`}>
                            {formError}
                        </Div>
                    );
                })}
            </Div>
        );
    }

    // -------------- RENDERS --------------

    renderCard_container(key, data, children, className, flexDirectionOveride, loopState) {
        const pr = this.props;
        loopState = loopState ? loopState : this.state;
        const type = Utils.get(data, "props.type");
        const cssid = Utils.get(data, "props.cssid");
        const name = Utils.get(data, "props.name");
        const title = Utils.get(data, "props.title");
        const enabled = Utils.get(data, "props.enabled", "false");
        const headlineTag = Utils.get(data, "props.headlineTag", "").toLowerCase();

        if (enabled == "userLoaded" && !pr.userLoaded) {
            return (
                <Spinner />
            )
        }

        // For items, you need to inject the param value for each item into the props value
        const param = Utils.get(data, "props.param");
        if (param) {
            const paramVal = Utils.get(loopState, param);
            //console.log("INJECTED FIELD CONTAINER!", title, name,data['id'], paramVal);
            data['props']['value'] = paramVal;
        }

        flexDirectionOveride = flexDirectionOveride ? flexDirectionOveride : "row"
        className = className ? className : "container";
        const flexDirection = Utils.get(data, "props.flexDirection", flexDirectionOveride); //data['flexDirection'] ? data['flexDirection'] : "row";

        const src = Utils.get(data, "props.src");
        //console.log("CONTAINER css", css, typeof css);
        let cardStyle = { ...this.styles.cardContainer }
        cardStyle['flexDirection'] = flexDirection;
        cardStyle = { ...cardStyle, ...this.getCss(data) };

        if (src) {
            cardStyle['backgroundImage'] = "url('" + src + "')";
        }
        if (Utils.get(data, "props.backgroundColor")) {
            cardStyle['backgroundColor'] = Utils.get(data, "props.backgroundColor");
        }
        // Handler for radio button selection
        let onClick = null;
        if (type == "radio" && name && name.length > 0) {
            const num = 1;
            const formName = Utils.get(data, "props.form_name", 'form1');
            let field = name;
            if (!Utils.empty(formName)) {
                field = formName + '_' + name;
            }
            let value = Utils.get(data, "props.value");
            onClick = this.handleSetRadioValue.bind(this, field, value, data['props']['value'])
            //console.log("field", field, this.state[field], this.state);
        } else if (type == "toggle") {
            const num = 1;
            const formName = Utils.get(data, "props.form_name", 'form1');
            const field = formName + '_' + name;
            let value = Utils.get(data, "props.value");
            onClick = this.handleToggleRadioValue.bind(this, field, value, data['props']['value'])
            //console.log("field", field, this.state[field], this.state);
        } else if (type == "link") {
            // Call goTo
            let url = Utils.get(data, "props.url", "#");
            if(url.indexOf("{") != -1) {
                url = Utils.parameterSubstitute(url, this.state, "");
                //console.log("parameterSubstitute URL", url);
            }
            const target = Utils.get(data, "props.target");
            onClick = this.goTo.bind(this, url, target);
        } else if (type == "anchor") {
            let anchor = Utils.get(data, "props.url");
            if (anchor.substr(0, 1) != "#") {
                anchor = "#" + anchor;
            }
            let url = document.URL.replace(/#.*$/, "");
            url = url + anchor;
            //console.log("Anchor", url);
            //window.location.href = redirectToURL;
            //window.location.reload(true)

            const target = Utils.get(data, "props.target");
            onClick = this.goTo.bind(this, url, target);
        } else if (type == "handler") {
            const target = Utils.get(data, "props.target");
            let value = Utils.get(data, "props.value");
            onClick = () => {
                if (this.props.postClickHandler) {
                    if (value.indexOf("form1_") != -1) {
                        value = Utils.get(this.state, value, "");
                    }
                    this.props.postClickHandler(target, value);
                } else {
                    console.log("Missing postClickHandler from wrapper. Trying Post.js local for target " + target + " for container " + name);
                    this.postClickHandler(target, value);
                }
            };
        } else if (type == "linkjson") {
            // TODO: Should be integrated into a button
            const url = Utils.get(data, "props.url");
            const title = Utils.get(data, "props.title");
            onClick = Api.getPostPutJson.bind(Api, "GET", "Custom", url, null, (o) => {
                // alert("Done with "+title);
                this.setState({
                    "field^artist_is_following": "yes"
                });
            });
        } else if (type == "linkpostjson") {
            // TODO: Should be integrated into a button
            const isLinkPostJson = (type == "linkpostjson");
            let method = "GET"
            let modalData = null;

            const url = Utils.get(data, "props.url");
            const name = Utils.get(data, "props.name");
            const value = Utils.get(data, "props.value");
            const linkpostjson = Utils.get(data, "props.linkpostjson");

            if (isLinkPostJson) {
                modalData = JSON.parse(linkpostjson);
                method = "POST"
            }

            const formName = Utils.get(data, "props.form_name", 'form1');
            let field = name;
            if (!Utils.empty(formName)) {
                field = formName + '_' + name;
            }

            onClick = Api.getPostPutJson.bind(Api, method, "Custom", url, modalData, (o) => {
                let tempState = {};
                tempState[field] = value;

                this.setState(tempState);
            });
        }

        const containerProps = {
            id: cssid,
            "data-name": name,
            "data-param": param,
            datatitle: Utils.get(data, "title"),
            datatitleid: Utils.get(data, "id"),
            className: className,
            key: key,
            style: cardStyle,
            onClick: onClick,
        };

        switch (headlineTag) {
            case "h1":
                return (
                    <H1 {...containerProps}>
                        {children}
                    </H1>
                );
                break;
            case "h2":
                return (
                    <H2 {...containerProps}>
                        {children}
                    </H2>
                );
                break;
            case "h3":
                return (
                    <H3 {...containerProps}>
                        {children}
                    </H3>
                );
                break;
            default:
                return (
                    <Div {...containerProps}>
                        {children}
                    </Div>
                );
        }
    }

    renderCard_parameter(key, data) {
        return <Div key={key} title={Utils.get(data, 'title')}></Div>
    }

    renderCard_icon(key, data) {
        const cardStyle = this.getCss(data);
        const faName = Utils.get(data, "props.faName", "edit");
        const size = Utils.get(data, "props.size", 12);
        return (
            <Div className="cmsIcon" key={key} datatitle={Utils.get(data, 'title')} style={cardStyle}>
                <Icon icon={faName} size={size} />
            </Div>
        )
    }

    renderArtistGalleryRow(key, data, artists) {
        const pr = this.props;
        const sty = this.styles;

        const cardStyle = this.getCss(data);
        const faName = Utils.get(data, "props.faName", "edit");
        const artistImageCss = Utils.get(data, "props.artistImageCss", {});

        const isCarousel = Utils.get(data, "props.isCarousel", "true") == "true";

        if (!artists) {
            artists = this.state["field^artists"];
        }
        const followingDict = Utils.arrayToObject(Utils.get(this.props, "user.following", []), "artist_id");

        let artistGalleryRow = StyleUtils.getMediaStyle('artistGalleryRow', sty.artistGalleryRow, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));
        let artistGalleryCard = StyleUtils.getMediaStyle('artistGalleryCard', sty.artistGalleryCard, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        let scrollStyle = null;
        let artistImageSizeStyle = null;
        let artistGalleryCardWidth = null;

        let artistImageSize = Utils.get(artistImageCss, "width", 220);

        if (isCarousel) {
            scrollStyle = sty.artistHorizontalScroll;
            artistImageSizeStyle = {
                width: pr.screen.mobile ? 120 : artistImageSize,
                height: pr.screen.mobile ? 120 : artistImageSize,
            };
        } else {
            scrollStyle = sty.artistWrap;
            artistImageSizeStyle = {
                width: pr.screen.mobile ? "100%" : artistImageSize,
                height: pr.screen.mobile ? "auto" : artistImageSize,
                // paddingBottom: pr.screen.mobile ? "150%" : "auto",
                paddingBottom: "150%",
            };
            artistGalleryCardWidth = pr.screen.mobile ? { width: "100%" } : null;
        }

        return (
            <Div className="cmsArtistGallery" key={`key-cmsArtistGallery-${artists.length}-${key}`} datatitle={Utils.get(data, 'title')} style={{ ...artistGalleryRow, ...scrollStyle, ...cardStyle }}>
                {artists.map((artist, artistIndex) => {
                    let isFollowing = false;
                    if (followingDict[artist.id]) {
                        isFollowing = true;
                    }

                    const aiEnabled = artist.ai_enabled;
                    return (
                        <Div
                            className="artistGalleryCard"
                            style={{ ...artistGalleryCard, ...artistGalleryCardWidth }}
                            key={`key-artistGalleryCard-${artist.id}`}
                        >
                            <Div
                                className="artistGalleryImage"
                                title={artist.title}
                                style={{
                                    ...sty.artistGalleryImage,
                                    ...artistImageSizeStyle,
                                    ...{ backgroundImage: `url(${artist.image_url})` },
                                    ...StylesPlatform.cursorPointer
                                }}
                                onClick={Utils.gotoRoute.bind(this, pr, `/p/creator_profile/${artist.name}/artist_id-${artist.id}`)}
                            >
                                {Utils.get(artist, "liveThisWeek") ?
                                    <Div className="liveThisWeek" style={sty.liveThisWeek}>
                                        LIVE THIS WEEK
                                    </Div>
                                    :
                                    null
                                }
                            </Div>
                            {aiEnabled ?
                                <Image
                                    src={apAi}
                                    style={{ width: 30, marginBottom: 0 }}
                                />
                                :
                                null
                            }

                            <Div className="artistTitle">{artist.title}</Div>
                            {/* {pr.user.following.map((x, i) => `${x.artist_id}, `)} */}
                            {aiEnabled ? (
                                isFollowing ? (
                                    <Button
                                        onClick={Utils.gotoRoute.bind(this, pr, `private/aichat/artist_id-${artist.id}`)}
                                        color="pinkGradient"
                                        size="small"
                                        style={sty.artistGalleryFollowButton}
                                    >
                                        Start Chatting →
                                    </Button>
                                ) : (
                                    <Button
                                        // onClick={this.handleFollowClick.bind(this, artist.id)}
                                        onClick={Utils.gotoRoute.bind(this, pr, `p/creator_profile/${artist.name}/artist_id-${artist.id}`)}
                                        color="pinkGradient"
                                        size="small"
                                        style={sty.artistGalleryFollowButton}
                                    >
                                        + Follow to Chat
                                    </Button>
                                )
                            ) : (
                                <Button
                                    // onClick={this.handleFollowClick.bind(this, artist.id)}
                                    onClick={Utils.gotoRoute.bind(this, pr, `p/creator_profile/${artist.name}/artist_id-${artist.id}`)}
                                    color={isFollowing ? "" : "white"}
                                    type={isFollowing ? "outlined" : ""}
                                    size="small"
                                    style={sty.artistGalleryFollowButton}
                                >
                                    {isFollowing ? "Following" : "Follow"}
                                </Button>
                            )}
                            {/* {artist.id} {isFollowing.toString()} */}
                        </Div>
                    );
                })}
            </Div>
        );
    }

    renderCard_artistgallery(key, data) {
        const pr = this.props;
        const sty = this.styles;

        const isCarousel = Utils.get(data, "props.isCarousel", "true") == "true";

        const artists = this.state["field^artists"];

        if (!artists.length) {
            return <Div>No Artists</Div>;
        }

        if (!pr.userLoaded) {
            return (
                <Spinner
                    message="Loading Creators"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 120 }}
                />
            );
        }

        const aiArtists = artists.filter((artist, i) => artist.ai_enabled ? artist : null);
        const normalArtists = artists.filter((artist, i) => artist.ai_enabled ? null : artist);

        const aiTitleStyle = { fontFamily: "Gazzetta", fontSize: 58, fontWeight: 500 };

        if (isCarousel) {
            return this.renderArtistGalleryRow(key, data);
        } else {
            return (
                <Div>
                    <Image
                        className="apAiLogo"
                        src={apAiLogo}
                        style={{ width: 173 }}
                    />
                    <Div className="aiTitleStyle" style={aiTitleStyle}>
                        AFTERPARTY AI CREATORS
                    </Div>
                    <br />
                    {this.renderArtistGalleryRow(key, data, aiArtists)}
                    <br />
                    <br />
                    <br />
                    <Div className="aiTitleStyle" style={aiTitleStyle}>
                        OTHER CREATORS YOU’LL LOVE
                    </Div>
                    <br />
                    {this.renderArtistGalleryRow(key, data, normalArtists)}
                </Div>
            );
        }
    }

    // NOTE: leave this here, it's just a placeholder for the purchase click because the click event is captured by the container
    // so this isn't doing anything
    goToPurchasePage() { }

    renderInstagalleryItem(type, instagalleryItem, key, bulletIndex, checkboxStateName, clickUrl, params) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if (checkboxStateName) {
            clickUrl = false;
        }

        let size = Utils.get(params, "size", 276);

        let igImage = null;
        let igIcon = null;
        let igImageUrl = Utils.get(instagalleryItem, 'image_url');
        if (!igImageUrl) {
            igImageUrl = Utils.get(instagalleryItem, 'original_image_url');
        }
        const mediaType = Utils.get(instagalleryItem, "media_type");

        switch (mediaType) {
            case "VIDEO":
                igImage = Utils.get(instagalleryItem, 'thumbnail_url');
                igIcon = <Icon className="igIcon" style={sty.igIcon} color="white" icon="film" />;
                break;
            case "CAROUSEL_ALBUM":
                igImage = Utils.get(instagalleryItem, 'media_url');
                if (!igImage) {
                    igImage = Utils.get(instagalleryItem, 'image_url');
                }
                igIcon = <Icon className="igIcon" style={sty.igIcon} color="white" icon="clone" />;
                break;
            default:
                igImage = Utils.get(instagalleryItem, 'media_url');
                if (!igImage) {
                    igImage = igImageUrl;
                }
        }

        const igDate = this.getIgDate(instagalleryItem);

        let igImageSizeStyle = {
            height: size,
            width: size,
            backgroundImage: `url('${igImage}')`,
        };

        if (size == "100%") {
            igImageSizeStyle.width = size;
            igImageSizeStyle.paddingBottom = size;
            igImageSizeStyle.height = "auto";
        }

        let igPostStyle = Utils.deepCopy(sty.igPostStyle);

        if (type == 4) {
            igPostStyle['border'] = "none";
            igPostStyle['paddingLeft'] = 17;
            igPostStyle['paddingRight'] = 17;
            igPostStyle['paddingTop'] = 0;
            igPostStyle['paddingBottom'] = 0;
        }

        if (type == 5 && Utils.get(instagalleryItem, 'listing_type') == 2) {
            igPostStyle['borderColor'] = Colors.accentGreen;
        }

        if (type == 10) {
            igPostStyle['borderColor'] = "#3A2B52";
            igPostStyle['maxWidth'] = params.maxWidth;
        }

        return (
            <Div key={`instagallery-${key}-${bulletIndex}`} className="igPost" style={igPostStyle}>
                <Div className="ig-image-container-outside" onClick={clickUrl ? Utils.gotoRoute.bind(this, this.props, clickUrl) : null} style={sty.igImageContainerOutside}>
                    <Div
                        className="ig-image-container"
                        style={{ ...sty.igImageContainerStyle, ...igImageSizeStyle }}
                    >
                        <Div className="igIcon">
                            {igIcon}
                        </Div>
                    </Div>
                    {type == 10 ?
                        <Button
                            style={{
                                width: "100%",
                                marginTop: 15,
                                marginBottom: -20,
                            }}
                            color="white"
                            onClick={this.goToPurchasePage.bind(this)}
                            subtitle={`+ ${Utils.get(instagalleryItem, "points", "")} leaderboard points`}
                            subtitleColor="#5F19C9"
                        >
                            Collect now
                        </Button>
                        :
                        null
                    }
                    {checkboxStateName ?
                        <Div className="ig-image-caption-container" style={sty.igImageCaptionContainer}>
                            <Div className="ig-image-caption-title" style={sty.igPostsContainer}>
                                {/* {Utils.abbreviateHash(Utils.get(instagalleryItem, 'caption'), 60, "...", "end")} */}
                                {/* {Utils.get(instagalleryItem, 'caption')} */}
                                {igDate ? igDate.format("MMM DD, YYYY") : null}
                            </Div>
                            {type == 2 ?
                                <Checkbox
                                    color="white"
                                    checked={st[checkboxStateName]}
                                    style={{ marginLeft: "auto" }}
                                    onClick={this.toggleInstagramItem.bind(this, checkboxStateName)}
                                />
                                :
                                null
                            }
                        </Div>
                        :
                        null
                    }
                    {!checkboxStateName && type != 7 && type != 5 ?
                        <Div className="ig-image-caption-container" style={sty.igImageCaptionContainer}>
                            <Div className="ig-image-caption-title" style={sty.igPostsContainer}>
                                {/* {Utils.abbreviateHash(Utils.get(instagalleryItem, 'caption'), 60, "...", "end")} */}
                                {/* {Utils.get(instagalleryItem, 'caption')} */}
                                {igDate ? igDate.format("MMM DD, YYYY") : null}
                            </Div>
                        </Div>
                        :
                        null
                    }
                    {!checkboxStateName && type == 5 ?
                        <Div className="ig-image-caption-container" style={{ ...sty.igImageCaptionContainer, ...{ flexDirection: "column" } }}>
                            <Div className="ig-image-caption-title" style={{ ...sty.igPostsContainer }}>
                                <Div style={{ fontSize: 24, marginRight: 6 }}>Total Sales:</Div>
                                <Div style={{ fontSize: 24, color: Colors.accentGreen }}>${Utils.get(instagalleryItem, 'total_sales', 0)}</Div>
                            </Div>
                            <Div style={{ ...sty.igPostsContainer, ...{ marginTop: 6, fontWeight: "normal" } }}>
                                <Div>Livestream: {Utils.get(instagalleryItem, 'live_stream_status', "Not Scheduled")}</Div>
                            </Div>
                        </Div>
                        :
                        null
                    }
                    {!checkboxStateName && type == 6 ?
                        <Div className="ig-image-caption-container ig-item-type-6" style={sty.igImageCaptionContainer}>
                            <Div>
                                <Div style={{ textAlign: "center", marginBottom: 12 }}>
                                    {Utils.get(instagalleryItem, 'title')}
                                </Div>
                                <Div className="ig-image-caption-title" style={sty.igPostsContainer}>
                                    {Utils.get(instagalleryItem, 'listing_type') == 2 ?
                                        <Button style={{ backgroundColor: Colors.accentGreen }}>Collect now</Button>
                                        :
                                        <Button>
                                            <Div style={{ fontWeight: "bold", marginRight: 6 }}>Collect now</Div>
                                            <Div style={{ fontSize: 12 }}>Only 50 left</Div>
                                        </Button>
                                    }

                                </Div>
                            </Div>
                        </Div>
                        :
                        null
                    }
                </Div>
            </Div>
        )
    }

    renderInstagalleryType1_Images(instagallery, cardStyle, data, key) {
        const sty = this.styles;

        return (
            <Div className='instagallery1' key={key} title={Utils.get(data, 'title')} style={{ display: "flex", flexDirection: "row", gap: 20, flexWrap: "wrap", }}>
                {instagallery.map((instagalleryItem, bulletIndex) => {
                    return this.renderInstagalleryItem(1, instagalleryItem, key, bulletIndex, false, "/p/digital_collectible/uid-" + Utils.get(this.props, "user.id") + "/collectible-" + Utils.get(instagalleryItem, "id"));
                })}
            </Div>
        )
    }

    renderInstagalleryType2_Checkboxes(instagallery, cardStyle, data, key, type) {
        type = type ? type : 2;
        const st = this.state;
        const sty = this.styles;

        const tilePostId = parseInt(Utils.get(data, "props.tilePostId"));
        const tilePost = Utils.get(this.state, `includedPosts.${tilePostId}.json_data.cards`);
        const instagalleryCrc = parseInt(Utils.get(data, "props.crc", 11));

        return (
            <Div className='instagallery2' key={key} datatitle={Utils.get(data, 'title')} style={{ ...sty.igGalleryStyle, ...cardStyle }}>
                {instagallery.map((instagalleryItem, bulletIndex) => {
                    const instaId = Utils.get(instagalleryItem, "id");

                    let loopState = {};
                    const igDate = this.getIgDate(instagalleryItem);
                    if (igDate) {
                        loopState["param^title2"] = igDate.format("MMM DD, YYYY");
                    }

                    const igClickUrl = this.getCollectibleUrl(instaId);
                    const checkboxField = `field^checkbox-${key}-${instaId}`;
                    return tilePost ?
                        this.renderCardLoop(tilePost, `tilePost-${instaId}`, loopState)
                        :
                        this.renderInstagalleryItem(type, instagalleryItem, checkboxField, bulletIndex, `form1_checkbox-${instagalleryCrc}-${instaId}`, igClickUrl);

                })}
            </Div>
        );
    }

    renderInstagalleryType3_fromUrl(instagallery, cardStyle, data, key) {
        const st = this.state;
        const sty = this.styles;

        let instagalleryItem = st.instaitem ? st.instaitem : null;

        const instaId = Utils.get(instagalleryItem, "id");

        return (
            <Div className='instagallery3' key={key} datatitle={Utils.get(data, 'title')} style={{ ...{ display: "flex", flexDirection: "row", backgroundColor: "#413750" }, ...cardStyle }}>
                {this.renderInstagalleryItem(3, instagalleryItem, `instaItemMostRecent-${key}-${instaId}`, 0, false, "/p/digital_collectible/uid-" + Utils.get(this.props, "user.id") + "/collectible-" + Utils.get(instagalleryItem, "id"))}
            </Div>
        );
    }

    renderInstagalleryType4_MostRecent(instagallery, cardStyle, data, key) {
        const pr = this.props;
        const sty = this.styles;

        let instagalleryItem = null;

        if (instagallery.length) {
            // Find first non-GA available
            for (var idx in instagallery) {
                if (Utils.get(instagallery, idx + ".nft_style") != 5) {
                    instagalleryItem = instagallery[idx];
                    break;
                }
            }
            if (!instagalleryItem) {
                instagalleryItem = instagallery[0];
            }
        }

        // for(let i = 0; i < instagallery.length; ++i) {
        //     let instagalleryItemTemp = instagallery[i];
        //
        //     if(Utils.get(instagalleryItemTemp, "media_type") != "VIDEO") {
        //         instagalleryItem = instagalleryItemTemp;
        //         break;
        //     }
        // }
        //
        // if(!instagalleryItem || Utils.get(instagalleryItem, "media_type") == "VIDEO") {
        //     return null;
        // }

        let params = {
            size: Utils.get(data, "props.igItemSize", 276),
        }

        const instaId = Utils.get(instagalleryItem, "id");

        return (
            <Div className='instagallery4' key={key} datatitle={Utils.get(data, 'title')} style={{ ...{ display: "flex", flexDirection: "row" }, ...cardStyle }}>
                {this.renderInstagalleryItem(4, instagalleryItem, `instaItemMostRecent-${key}-${instaId}`, 0, false, "/p/digital_collectible/uid-" + Utils.get(this.props, "user.id") + "/collectible-" + Utils.get(instagalleryItem, "id"), params)}
            </Div>
        );
    }

    renderInstagalleryType5_SalesDashboard(instagallery, cardStyle, data, key) {
        const sty = this.styles;

        const selectedType = !Utils.get(this.state, "form1_field^chk_evergreen") ? 1 : 2; // Normal
        //const selectedType = 2; // Evergeen

        return (
            <Div className='instagallery5' key={key} datatitle={Utils.get(data, 'title')} style={{ ...sty.igGalleryStyle, ...cardStyle }}>
                {instagallery.map((instagalleryItem, bulletIndex) => {
                    // Note: I don't think the tabbed interface will work very well
                    if (Utils.get(instagalleryItem, 'listing_type') == selectedType) {
                        return this.renderInstagalleryItem(5, instagalleryItem, key, bulletIndex, false, `/p/digital_collectible?collectible=${Utils.get(instagalleryItem, "id")}`);
                    } else {
                        return null;
                    }
                })}
            </Div>
        )

    }

    renderInstagalleryType6_AllExceptMostRecent(instagallery, cardStyle, data, key) {
        const sty = this.styles;

        if (instagallery.length < 2) {
            return null;
        }

        let firstIndex = 0;

        for (let i = 0; i < instagallery.length; i++) {
            let instagalleryItem = instagallery[i];
            if (Utils.get(instagalleryItem, "media_type") != "VIDEO") {
                firstIndex = i + 1;
                break;
            }
        }

        const instagalleryModified = instagallery.slice(firstIndex);

        return (
            <Div key={key} datatitle={Utils.get(data, 'title')} style={{ ...sty.igGalleryStyle, ...cardStyle }}>
                {instagalleryModified.map((instagalleryItem, bulletIndex) => {
                    const instaId = Utils.get(instagalleryItem, "id");

                    return this.renderInstagalleryItem(6, instagalleryItem, `instaAllExceptMostRecent-${key}-${instaId}`, bulletIndex, false, "/p/digital_collectible/uid-" + Utils.get(this.props, "user.id") + "/collectible-" + Utils.get(instagalleryItem, "id"));
                })}
            </Div>
        );
    }

    renderInstagalleryType7_Sales(instagallery, cardStyle, data, key) {
        const sty = this.styles;

        return (
            <Div className='instagallery7' key={key} datatitle={Utils.get(data, 'title')} style={{ ...sty.igGalleryStyle, ...cardStyle }}>
                {instagallery.map((instagalleryItem, bulletIndex) => {
                    return this.renderInstagalleryItem(7, instagalleryItem, key, bulletIndex, false, `/p/digital_collectible/uid-${Utils.get(this.props, "user.id")}/collectible-${Utils.get(instagalleryItem, "id")}`);
                })}
            </Div>
        )
    }

    renderInstagalleryType8_MostRecentBuyButton(instagallery, cardStyle, data, key) {
        console.log("RENDER8");
        const sty = this.styles;
        const buttonUrl = "/p/digital_collectible?collectible=" + Utils.get(instagallery, "0.id");
        const color = Utils.get(data, "props.color", "pinkGradient");

        return (
            <Div className='instagallery8' key={key} datatitle={Utils.get(data, 'title')} style={{ ...sty.igGalleryStyle, ...cardStyle }}>
                <Button
                    onClick={() => { this.goTo(buttonUrl) }}
                    style={cardStyle}
                    color={color}
                    type={Utils.get(data, 'type')}
                    key={`clt-btn-${key}`}
                >
                    Buy Now
                </Button>
            </Div>
        )
    }

    getCollectibleUrl(collectibleId) {
        return `/p/digital_collectible/collectible-${collectibleId}`
    }

    renderInstagalleryType10_CollectNowArtistPage(instagallery, cardStyle, data, key) {
        const pr = this.props;
        const sty = this.styles;

        let params = {
            size: pr.screen.mobile ? 276 : 276,
        }

        params.size = Utils.get(data, "props.igItemSize", 276);
        params.maxWidth = Utils.get(data, "props.igItemMaxWidth", 200);

        return (
            <Div className='instagallery10' key={key} datatitle={Utils.get(data, 'title')} style={{ ...sty.igGalleryStyle, ...cardStyle }}>
                {instagallery.map((instagalleryItem, bulletIndex) => {
                    // Hide GA collectibles
                    if (Utils.get(instagalleryItem, 'nft_style') == 5) {
                        return null;
                    } else {
                        return this.renderInstagalleryItem(10, instagalleryItem, key, bulletIndex, false, this.getCollectibleUrl(Utils.get(instagalleryItem, "id")), params);
                    }
                })}
            </Div>
        )
    }

    renderCard_instagallery(key, data) {
        const instagallery = Utils.get(this.state, "instagallery");
        const instaConfigHost = Utils.get(this.state, "instaconfig.host", '');
        const instaConfigClientId = Utils.get(this.state, "instaconfig.clientId", '');
        const redirectUrl = instaConfigHost + "/instagram_connect";
        const igUrl = "https://www.instagram.com/oauth/authorize?client_id=" + instaConfigClientId + "&redirect_uri=" + redirectUrl + "&scope=user_profile,user_media&response_type=code";
        //console.log("igUrl", igUrl);
        const assetsUrl = "/post/60";

        const validate = Utils.get(data, "props.validate", "false") == "true";
        let disabled = Utils.get(data, "props.disabled", "false") == "true";
        if (validate && !disabled) {
            let out = {};
            const rules = [
                { type: "accept", field: "form1_field^accept" },
            ];
            disabled = !Validate.valid(rules, out, this.state);
        }

        const color = Utils.get(data, "props.color", "pinkGradient");
        const type = parseInt(Utils.get(data, "props.type", 1));
        const cardStyle = this.getCss(data);

        if (type == 1 || (type == 2 && !instagallery)) {
            let buttonUrl = igUrl;
            let buttonTitle = Utils.get(data, 'buttonTitle', "Connect to Instagram");
            if (instagallery) {
                //buttonUrl = assetsUrl;
                //buttonTitle = "View My Sales";
            }
            return (
                <Button
                    onClick={() => { this.goTo(buttonUrl) }}
                    style={cardStyle}
                    color={color}
                    type={Utils.get(data, 'type')}
                    disabled={disabled}
                    key={`clt-btn-${key}`}
                >
                    {buttonTitle}
                </Button>
            );
        } else if (!instagallery || instagallery.length == 0) {
            return (
                <Div key={`clt-no-posts-${key}`} style={{ fontSize: 36, marginBottom: 24, }}>
                    No gallery posts available
                </Div>
            )
        }
        //console.log("renderIGType", type);
        switch (type) {
            case 1:
                return this.renderInstagalleryType1_Images(instagallery, cardStyle, data, key);

            // 2==IG Items for creator to approve with checkboxes, 9=IG Items without checkboxes
            case 2:
            case 9:
                return this.renderInstagalleryType2_Checkboxes(instagallery, cardStyle, data, key, type);

            case 3:
                return this.renderInstagalleryType3_fromUrl(instagallery, cardStyle, data, key);
            case 4:
                return this.renderInstagalleryType4_MostRecent(instagallery, cardStyle, data, key);
            case 5:
                return this.renderInstagalleryType5_SalesDashboard(instagallery, cardStyle, data, key);
            case 6:
                return this.renderInstagalleryType6_AllExceptMostRecent(instagallery, cardStyle, data, key);
            case 7:
                return this.renderInstagalleryType7_Sales(instagallery, cardStyle, data, key);
            case 8:
                return this.renderInstagalleryType8_MostRecentBuyButton(instagallery, cardStyle, data, key);
            case 10:
                return this.renderInstagalleryType10_CollectNowArtistPage(instagallery, cardStyle, data, key);
            default:
                return null;
        }
    }

    renderCard_banner(key, data) {
        return <Div key={key}><Image src={"http://dan.soindrop.com/images/banners/Banner_Guardians_Presale.png"} /></Div>
    }

    renderCard_calendar(key, data) {
        return (
            <Div key={key}>
                <Calendar />
            </Div>
        )
    }

    renderCard_video(key, data, loopState) {
        const st = this.state;

        loopState = loopState ? loopState : this.state;
        let src = Utils.get(data, "props.videoUrl", null);
        if (src.indexOf('^') != -1) {
            //console.log("src Item video", Utils.get(loopState, src))
            let stateAnimation = Utils.get(loopState, src);
            if (stateAnimation) {
                src = stateAnimation;
            }
        }
        let poster = Utils.get(data, "props.imageUrl", null);
        if (Utils.getRequest('novideo')) {
            src = null;
        }
        if (poster.indexOf('^') != -1) {
            //console.log("src Item poster", Utils.get(loopState, poster))
            let stateImage = Utils.get(loopState, poster);
            if (stateImage) {
                poster = stateImage;
            }
        }
        const autoPlay = Utils.get(data, "props.autoplay", "").toLowerCase() === "true";
        let muted = Utils.get(data, "props.muted", "").toLowerCase() === "true" || autoPlay;
        const playsInline = Utils.get(data, "props.playsInline", "").toLowerCase() === "true";
        const controls = Utils.get(data, "props.controls", "").toLowerCase() === "true";
        const loop = Utils.get(data, "props.loop", "").toLowerCase() === "true";
        if (autoPlay) {
            muted = true;
        }

        if (!src && !poster) {
            ll._("Missing video and image");
            return null;
        }
        src = this.setCdnUrl(src);
        poster = this.setCdnUrl(poster);

        const cardStyle = this.getCss(data);

        if ((src == null || !src.includes(".mp4")) && poster) {
            return (
                <Image
                    className="cardVideoLowPower"
                    key={key}
                    src={poster}
                    style={cardStyle}
                />
            );
        }

        return (
            <Div className="cardVideo" key={key} style={cardStyle}>
                <video
                    src={src}
                    autoPlay={autoPlay}
                    muted={muted}
                    // playsInline={playsInline}
                    poster={poster}
                    controls={controls}
                    style={cardStyle}
                    loop={loop}
                    playsInline={true}
                    webkit-playsinline="true"
                ></video>
            </Div>
        );
    }

    renderCard_items(key, data, loopState) {
        const st = this.state;
        const sty = this.styles;

        // NOTE: loopState is the state if we're rendering inside a loop
        loopState = loopState ? loopState : this.state;
        const cardStyle = this.getCss(data);

        const tilePostId = parseInt(Utils.get(data, "props.partialPostId"));
        const tilePostAltId = parseInt(Utils.get(data, "props.partialPostAltId", 0));
        //const tilePost = Utils.get(this.state, `includedPosts.${tilePostId}.json_data.cards`);
        const tilePost = Utils.get(this.state, `includedPosts.${tilePostId}.json_data.cards`);
        const tilePostAlt = Utils.get(this.state, `includedPosts.${tilePostAltId}.json_data.cards`, {});
        //console.log("tilePostAlt", tilePostAltId, tilePostAlt);
        const instagalleryCrc = parseInt(Utils.get(data, "props.crc", 11));
        let itemStateDataSelector = 'field^items';
        const items = Utils.get(this.state, itemStateDataSelector, []);
        // TODO: Audio chip data
        if (Utils.get(data, "props.itemType") == 2) {
            //console.log("ARTIST old", Utils.get(this.state, itemStateDataSelector, []));
            //console.log("ARTIST", Utils.get(this.state, "field^artist.audio_examples", []));
            //itemStateDataSelector = 'field^artist.audio_examples';
            for (var idx in items) {
                var item = items[idx];
                item['audioUrl'] = "/images/genaudio/f6b38c3d-4e23-43ca-a427-c177bd5e2145.mp3";
            }
        }

        return (
            <Div className='items' key={key} datatitle={Utils.get(data, 'title')} style={cardStyle}>
                {items.map((item, bulletIndex) => {
                    const itemId = Utils.get(item, "id");
                    let loopState = { "field^item": item };
                    const renderPost = tilePostAlt && (!item['initial_price'] || item['initial_price'] == 0) ? tilePostAlt : tilePost
                    //console.log("item", item, renderPost);

                    return this.renderCardLoop(renderPost, `itemPost-${itemId}`, loopState);

                })}
            </Div>
        );
    }


    renderCard_text(key, data, loopState) {
        // NOTE: loopState is the state if we're rendering inside a loop
        loopState = loopState ? loopState : this.state;

        let text = Utils.get(data, "props.text") ? Utils.get(data, "props.text") : Utils.get(data, "props.description");
        if (this.state.renderHtml) {
            this.htmlStr += '<div>' + text + '</div>';
        }
        const param = Utils.get(data, "props.param");
        const headlineTag = Utils.get(data, "props.headlineTag", "").toLowerCase();

        if (param) {
            var paramVal = Utils.get(loopState, param);
            if (paramVal) {
                const paramFormat = Utils.get(data, "props.paramFormat");
                if (paramFormat == "dollars_commas") {
                    // TODO: Format
                    paramVal = "$" + Utils.commas(paramVal);
                } else if (paramFormat == "commas") {
                    // TODO: Format
                    paramVal = Utils.commas(paramVal);
                } else if (paramFormat == "points") {
                    paramVal = Utils.commas(paramVal) + " points";
                } else if (paramFormat == "date_MDY") {
                    paramVal = Utils.getFomattedDateFromCreatedAt(paramVal)
                } else if (paramFormat == "date_MDY_isTime") {
                    paramVal = Utils.getFomattedDateFromCreatedAt(paramVal, true)
                } else if (paramFormat == "date_timeFromDate") {
                    paramVal = Utils.getTimeFromDate(paramVal)
                } else if (paramFormat == "@") {
                    paramVal = "@" + paramVal;
                } else if (paramFormat == "math_floorUnits") {
                    paramVal = parseInt(parseInt(paramVal) / 10) * 10;
                }

                ll._("FIELDPARAM", param, text);
                text = paramVal;
            }
        }
        const cardStyle = this.getCss(data);

        // Handle embedded links
        const linkColor = Utils.get(cardStyle, "linkColor", Colors.magenta);

        const textArray = this.plainTextToRichTextArray(text, linkColor, key);

        let textBody = textArray;

        switch (headlineTag) {
            case "h1":
                textBody = <H1>{textArray}</H1>
                break;
            case "h2":
                textBody = <H2>{textArray}</H2>
                break;
            case "h3":
                textBody = <H3>{textArray}</H3>
                break;
        }

        return (
            <Div key={key} style={cardStyle} datatitle={Utils.get(data, "title")}>
                {textBody}
            </Div>
        );
    }

    renderAvatarPhoto(imageUrl, size, num, borderColor, borderSize, isMe, prScreenMobile) {
        const pr = this.props;

        imageUrl = imageUrl && imageUrl.length > 0 ? imageUrl : "/images/avatars/male01.png";
        let imageStyle = { width: size, height: size, background: Color.purpleDark, borderRadius: 100, boxSizing: "border-box", alignSelf: "center" };
        let avatarNum = $.extend(true, {}, this.styles.avatarNum);

        if (borderColor) {
            borderColor = "transparent";
            borderSize = borderSize ? borderSize : 3;
            borderSize = pr.screen.mobile ? 2 : borderSize;
            imageStyle['border'] = `${borderSize}px solid ${borderColor}`;
            // imageStyle['background'] = "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)";
            if (num == 1) {
                imageStyle["filter"] = "filter: drop-shadow(0px 3.49166px 73.3248px #FFD466)";
                imageStyle['background'] = "linear-gradient(#C92D7A, #FFB84F) padding-box, linear-gradient(0deg, #C92D7A, #FFB84F) border-box";
                imageStyle["boxShadow"] = prScreenMobile ? "none" : "0px 3.4916584491729736px 73.3248291015625px 0px #FFD466";
            } else if (num == 2) {
                imageStyle['background'] = "linear-gradient(#7A6A80, #DEDEDE) padding-box, linear-gradient(0deg, #7A6A80, #DEDEDE) border-box";
            } else if (num == 3) {
                imageStyle['background'] = "linear-gradient(#6B2222, #AF6660) padding-box, linear-gradient(0deg, #6B2222, #AF6660) border-box";
            } else {
                imageStyle["filter"] = "drop-shadow(0px 2.63157px 15.7894px #0F0420)";
                imageStyle['background'] = "linear-gradient(#C92D7A, #EE6E62) padding-box, linear-gradient(135deg, #C92D7A, #EE6E62) border-box";
            }

            avatarNum['background'] = "linear-gradient(135deg, #C92D7A 0%, #EE6E62 100%)";
            avatarNum['color'] = "white";
            avatarNum['paddingTop'] = 4;
            avatarNum['fontWeight'] = 500;
            avatarNum["fontSize"] = pr.screen.mobile ? 12 : avatarNum["fontSize"];
        }

        return (
            <Div style={{ width: "fit-content", display: "flex", alignSelf: "center", position: "relative" }}>
                <Image className="avatarPicture imageStyle" style={imageStyle} src={imageUrl} />
                {isMe ?
                    <Div className="avatarNumber" style={avatarNum}>YOU</Div>
                    :
                    null
                }
            </Div>
        );
    }

    renderCard_leaderboard(key, data) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        let cardStyle = this.getCss(data);
        const leaders = Utils.get(this.state, 'leaderboard.leaders', []);
        const currentUser = Utils.get(this.state, 'leaderboard.current_user', []);
        const leaderboardType = Utils.get(data, 'props.type', 1);

        const leader1 = Utils.get(leaders, '0');
        const leader2 = Utils.get(leaders, '1');
        const leader3 = Utils.get(leaders, '2');
        const otherLeaders = leaders.slice(3, 11);

        const mediaQuery = leaderboardType == 2 ? 'md' : StyleUtils.getWidthType(pr.screen.width, 'md');
        const prScreenMobile = leaderboardType == 2 ? true : false;
        const artistId = Utils.get(st, "field^items.0.artist_id") ? Utils.get(st, "field^items.0.artist_id") : Utils.getRequest('artist_id', undefined, false, true);
        return (
            <Div
                style={cardStyle}
                className="leaderboardCmsContainer"
            >
                <Leaderboard
                    screen={pr.screen}
                    type={leaderboardType}
                    artistId={artistId}
                    user={pr.user}
                />
            </Div>
        )
    }

    renderCard_leaderboardOld(key, data) {
        let cardStyle = this.getCss(data);
        // cardStyle['width'] = 400;
        // cardStyle['minHeight'] = 700;
        // cardStyle['backgroundColor'] = "#DB8A61";
        // cardStyle['borderRadius'] = 20;
        // cardStyle['padding'] = 20;
        // cardStyle['overflow'] = "hidden";
        // cardStyle['boxShadow'] = "inset 60px 0 120px #1F0744, inset -60px 0 120px #D55F65";
        //console.log('pageInfo', Utils.get(this.state, 'leaderboard'));
        const leaders = Utils.get(this.state, 'leaderboard.leaders', []);
        const currentUser = Utils.get(this.state, 'leaderboard.current_user', []);
        const leaderboardType = Utils.get(data, 'props.type', 1);

        const leader1 = Utils.get(leaders, '0');
        const leader2 = Utils.get(leaders, '1');
        const leader3 = Utils.get(leaders, '2');
        const otherLeaders = leaders.slice(3, 11);
        //console.log('leader1', leader1);

        const pr = this.props;
        const sty = this.styles;

        const mediaQuery = leaderboardType == 2 ? 'md' : StyleUtils.getWidthType(pr.screen.width, 'md');
        const prScreenMobile = leaderboardType == 2 ? true : false;

        const otherLeadersContainer = StyleUtils.getMediaStyle('otherLeadersContainer', sty.otherLeadersContainer, sty, mediaQuery);
        const usernameText = StyleUtils.getMediaStyle('usernameText', sty.usernameText, sty, mediaQuery);
        const leaderPointsInRow = StyleUtils.getMediaStyle('leaderPointsInRow', sty.leaderPointsInRow, sty, mediaQuery);
        const usernameTextTop = StyleUtils.getMediaStyle('usernameTextTop', sty.usernameTextTop, sty, mediaQuery);
        const leaderboardHeader = StyleUtils.getMediaStyle('leaderboardHeader', sty.leaderboardHeader, sty, mediaQuery);
        const userLeaderboardContainer = StyleUtils.getMediaStyle('userLeaderboardContainer', sty.userLeaderboardContainer, sty, mediaQuery);
        const leaderboardFirstPlace = StyleUtils.getMediaStyle('leaderboardFirstPlace', sty.leaderboardFirstPlace, sty, mediaQuery);
        const leaderboardSecondPlace = StyleUtils.getMediaStyle('leaderboardSecondPlace', sty.leaderboardSecondPlace, sty, mediaQuery);
        const leaderboardThirdPlace = StyleUtils.getMediaStyle('leaderboardThirdPlace', sty.leaderboardThirdPlace, sty, mediaQuery);
        const leaderboardRow = StyleUtils.getMediaStyle('leaderboardRow', sty.leaderboardRow, sty, mediaQuery);
        const rankPoints = StyleUtils.getMediaStyle('rankPoints', sty.rankPoints, sty, mediaQuery);
        const userPoints = StyleUtils.getMediaStyle('userPoints', sty.userPoints, sty, mediaQuery);
        const fanMessage = StyleUtils.getMediaStyle('fanMessage', sty.fanMessage, sty, mediaQuery);
        const trophyIcon = StyleUtils.getMediaStyle('trophyIcon', sty.trophyIcon, sty, mediaQuery);
        const trophyIconSmaller = StyleUtils.getMediaStyle('trophyIconSmaller', sty.trophyIconSmaller, sty, mediaQuery);
        const gradientTrophyBackground = StyleUtils.getMediaStyle('gradientTrophyBackground', sty.gradientTrophyBackground, sty, mediaQuery);
        const usernamePointsContainer = StyleUtils.getMediaStyle('usernamePointsContainer', sty.usernamePointsContainer, sty, mediaQuery);
        const largeQuoteContainer = StyleUtils.getMediaStyle('largeQuoteContainer', sty.largeQuoteContainer, sty, mediaQuery);
        const leaderPoints = StyleUtils.getMediaStyle('leaderPoints', sty.leaderPoints, sty, mediaQuery);
        const rankText = StyleUtils.getMediaStyle('rankText', sty.rankText, sty, mediaQuery);

        const pinkDotQuoteLeft = <Div className="pinkDotQuoteLeft" style={sty.pinkDotQuoteLeft}>“</Div>;
        const pinkDotQuoteRight = <Div className="pinkDotQuoteRight" style={sty.pinkDotQuoteRight}>”</Div>;

        if (leaders.length == 0) {
            return (
                <Div style={{ color: "white" }}>
                    There are currently no leaders
                </Div>
            )
        }

        return (
            <Div
                style={{ ...sty.leaderboardElement, ...cardStyle }}
                className="leaderboardElement"
                id="leaderboard"
            >
                <Div
                    style={leaderboardHeader}
                    className="leaderboardHeader"
                >
                    {/* NOTE: 1ST PLACE */}
                    <Div
                        style={leaderboardFirstPlace}
                        className="leaderboardFirstPlace"
                    >
                        {this.renderAvatarPhoto(Utils.get(leader1, 'photo'), prScreenMobile ? 56 : 131, 1, Colors.magenta, 3, false, prScreenMobile)}
                        <Div className="largeQuoteContainer" style={largeQuoteContainer}>
                            <Div className="usernameTextTop" style={usernameTextTop}>
                                @{Utils.formatUsername(Utils.get(leader1, 'username'), 20, false, "end")}
                            </Div>
                            <Div className="quoteTextContainer" style={sty.quoteTextContainer}>
                                {prScreenMobile ? null : pinkDotQuoteLeft}
                                <Div className="fanMessage" style={fanMessage}>
                                    {Utils.shortenString(Utils.get(leader1, 'artist_question', "..."), 100, true)}
                                </Div>
                                {prScreenMobile ? null : pinkDotQuoteRight}
                            </Div>

                        </Div>

                        <Div>
                            <Div className="gradientTrophyBackground" style={gradientTrophyBackground}>
                                <Image
                                    style={trophyIcon}
                                    className="trophyIcon"
                                    src="https://dhwk6gixvyfl3.cloudfront.net/upload_9267b88583f7d63623387a14802c2bbc.png"
                                />
                                <Div className="leaderPoints" style={leaderPoints}>
                                    {Utils.get(leader1, 'points')}
                                </Div>
                            </Div>
                        </Div>
                    </Div>

                    {/* NOTE: 2ND PLACE */}
                    {leader2 ?
                        <Div
                            style={sty.leaderboardSecondPlaceContainer}
                            className="leaderboardSecondPlaceContainer"
                        >
                            <Div
                                style={leaderboardSecondPlace}
                                className="leaderboardSecondPlace"
                            >
                                {this.renderAvatarPhoto(Utils.get(leader2, 'photo'), prScreenMobile ? 56 : 98, 2, Colors.magenta)}
                                <Div className="largeQuoteContainer" style={largeQuoteContainer}>
                                    <Div className="usernameTextTop" style={usernameTextTop}>
                                        @{Utils.formatUsername(Utils.get(leader2, 'username'), 20, false, "end")}
                                    </Div>
                                    <Div className="quoteTextContainer" style={sty.quoteTextContainer}>
                                        {prScreenMobile ? null : pinkDotQuoteLeft}
                                        <Div className="fanMessage" style={fanMessage}>
                                            {Utils.shortenString(Utils.get(leader2, 'artist_question', "..."), 100, true)}
                                        </Div>
                                        {prScreenMobile ? null : pinkDotQuoteRight}
                                    </Div>

                                </Div>

                                <Div className="gradientTrophyContainer" style={{ marginLeft: "auto" }}>
                                    <Div className="gradientTrophyBackground" style={{ ...gradientTrophyBackground, ...{ width: prScreenMobile ? 65 : 112, height: prScreenMobile ? 75 : 131 } }}>
                                        <Image
                                            style={trophyIconSmaller}
                                            className="trophyIconSmaller"
                                            src="https://dhwk6gixvyfl3.cloudfront.net/upload_7d6304ab37e2cc2f2a87ae481365246d.png"
                                        />
                                        <Div className="leaderPoints" style={{ ...leaderPoints, ...{ fontSize: prScreenMobile ? 16 : 28 } }}>
                                            {Utils.get(leader2, 'points')}
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        :
                        null
                    }

                    {/* NOTE: 3RD PLACE */}
                    {leader3 ?
                        <Div
                            style={sty.leaderboardThirdPlaceContainer}
                            className="leaderboardThirdPlaceContainer"
                        >
                            <Div
                                style={leaderboardThirdPlace}
                                className="leaderboardThirdPlace"
                            >
                                {this.renderAvatarPhoto(Utils.get(leader3, 'photo'), prScreenMobile ? 56 : 98, 3, Colors.magenta)}
                                <Div className="largeQuoteContainer" style={largeQuoteContainer}>
                                    <Div className="usernameTextTop" style={usernameTextTop}>
                                        @{Utils.formatUsername(Utils.get(leader3, 'username'), 20, false, "end")}
                                    </Div>
                                    <Div className="quoteTextContainer" style={sty.quoteTextContainer}>
                                        {prScreenMobile ? null : pinkDotQuoteLeft}
                                        <Div className="fanMessage" style={fanMessage}>
                                            {Utils.shortenString(Utils.get(leader3, 'artist_question', "..."), 100, true)}
                                        </Div>
                                        {prScreenMobile ? null : pinkDotQuoteRight}
                                    </Div>

                                </Div>

                                <Div className="gradientTrophyContainer" style={{ marginLeft: "auto" }}>
                                    <Div className="gradientTrophyBackground" style={{ ...gradientTrophyBackground, ...{ width: prScreenMobile ? 65 : 112, height: prScreenMobile ? 75 : 131 } }}>
                                        <Image
                                            style={trophyIconSmaller}
                                            className="trophyIconSmaller"
                                            src="https://dhwk6gixvyfl3.cloudfront.net/upload_7bd4b0556e594c8752a410f9ed6afb53.png"
                                        />
                                        <Div className="leaderPoints" style={{ ...leaderPoints, ...{ fontSize: prScreenMobile ? 16 : 28 } }}>
                                            {Utils.get(leader3, 'points')}
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        :
                        null
                    }
                </Div>

                {/* NOTE: YOU i.e. Current User */}
                {Utils.get(currentUser, 'id') ?
                    <Div
                        className="userLeaderboardContainer"
                        style={userLeaderboardContainer}
                    >
                        {this.renderAvatarPhoto(Utils.get(currentUser, 'photo'), prScreenMobile ? 56 : 92, 0, Colors.magenta, 3, true)}
                        <Div className="usernamePointsContainer" style={usernamePointsContainer}>
                            <Div className="usernameTextTop" style={{ ...usernameTextTop, ...{ paddingLeft: 0 } }}>
                                @{Utils.formatUsername(Utils.get(currentUser, 'username'), 20, false, "end")}
                            </Div>
                            <Div className="userPoints" style={userPoints}>
                                {Utils.get(currentUser, 'points')}
                            </Div>
                        </Div>

                        <Div className="rankPointsContainer" style={sty.rankPointsContainer}>
                            <Div className="rankText" style={rankText}>
                                {Utils.get(currentUser, 'points') == 0 ?
                                    ""
                                    :
                                    "Rank"
                                }
                            </Div>
                            <Div className="rankPoints" style={rankPoints}>
                                {Utils.get(currentUser, 'points') == 0 ?
                                    <Div style={{ fontSize: 26 }}>
                                        Not<br />Ranked
                                    </Div>
                                    :
                                    Utils.get(currentUser, 'rank')
                                }
                            </Div>
                        </Div>
                    </Div>
                    :
                    null
                }

                {/* NOTE: TOP 4 - 10 Users */}
                <Div className="otherLeadersContainer" style={otherLeadersContainer}>
                    {otherLeaders.map((leaderItem, bulletIndex) => {
                        return (
                            <Div key={`leaders-${key}-${bulletIndex}`} className="leaderboardRow" style={leaderboardRow}>
                                {this.renderAvatarPhoto(Utils.get(leaderItem, 'photo'), prScreenMobile ? 56 : 92, 0, Colors.magenta)}
                                <Div className="usernamePointsContainer" style={usernamePointsContainer}>
                                    <Div className="usernameTextTop" style={{ ...usernameTextTop, ...{ paddingLeft: 0 } }}>
                                        @{Utils.formatUsername(Utils.get(leaderItem, 'username'), 20, false, "end")}
                                    </Div>
                                    <Div className="userPoints" style={userPoints}>
                                        {Utils.get(leaderItem, 'points')}
                                    </Div>
                                </Div>

                                <Div className="rankPointsContainer" style={sty.rankPointsContainer}>
                                    <Div className="rankText" style={rankText}>
                                        Rank
                                    </Div>
                                    <Div className="rankPoints" style={rankPoints}>
                                        {Utils.get(leaderItem, 'rank')}
                                    </Div>
                                </Div>
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        );
    }

    renderCard_bulletlist(key, data) {
        const text = Utils.get(data, "props.description");
        if (!text) {
            return null;
        }
        const cardStyle = this.getCss(data);
        const itemStyle = { marginBottom: 8 };
        const bullets = text.split("\n");
        const bulletsHtml = bullets.map((bulletItem, bulletIndex) => {
            return <li key={`bulletlist-${key}-${bulletIndex}`} style={itemStyle}>{bulletItem}</li>
        });

        return <Div key={key} style={cardStyle}>
            <ul style={{ "paddingInlineStart": 20 }}>{bulletsHtml}</ul>
        </Div>
    }

    renderCard_html(key, data) {
        return <Div key={key}>{Utils.get(data, 'title')}</Div>
    }

    renderCard_image(key, data, loopState) {
        loopState = loopState ? loopState : this.state;

        //console.log("IMAGE", data);
        let src = Utils.get(data, "props.src");
        const param = Utils.get(data, "props.param");
        if (param) {
            const tempUrl = Utils.get(loopState, param);
            if (tempUrl) {
                src = tempUrl;
            }
        }

        if (!src || src.length == 0) {
            return null;
        }
        //console.log("src", src)
        src = this.setCdnUrl(src);
        const cardStyle = this.getCss(data, null, {});
        const className = Utils.get(data, "props.title");
        const objectFit = Utils.get(data, "props.objectFit");
        const alt = !Utils.empty(Utils.get(data, "props.alt")) ? Utils.get(data, "props.alt") : null;

        let imgStyle = { width: "100%", "verticalAlign": "bottom" };

        if (objectFit) {
            imgStyle.objectFit = objectFit;
            imgStyle.height = "100%";
        }

        return (
            <Div key={key} style={{ ...cardStyle, ...{ "overflow": "hidden" } }} className={className} datatitle={Utils.get(data, "title")}>
                <Image
                    style={imgStyle}
                    src={src}
                    alt={alt}
                />
            </Div>
        );
    }

    renderCard_lottie(key, data, loopState) {
        const st = this.state;

        loopState = loopState ? loopState : this.state;
        const cardId = Utils.get(data, "id");

        //console.log("IMAGE", data);
        let src = Utils.get(data, "props.src");
        let animationData = Utils.get(data, "props.animationData");

        // Lottie file is loaded async as a JSONP added to the doc by the script tag in processPageInfo()
        if(src.indexOf(".js") != -1) {
            //console.log("Checking if Lottie CDN file "+src+" is loaded");
            const parts = src.split("/");
            const filename = parts[parts.length-1];
            if(this.state[filename]) {
                console.log("Lottie CDN "+src+" file loaded");
                animationData = this.state[filename];
                src = null;
            } else {
                return null;
            }
        }

        if(Utils.empty(src) && Utils.empty(animationData)) {
            return null;
        }
        if(Utils.empty(animationData)) {
            animationData = "{}";
        }
        if(!Utils.empty(src)) {
            animationData = null;
        }
        if(!Utils.empty(animationData)) {
            // TODO: Check for JSON data already an object
            try {
                if(typeof animationData != 'object') {
                    animationData = JSON.parse(animationData);
                }
                const param = Utils.get(data, "props.param");
                if (param) {
                    const tempUrl = Utils.get(loopState, param);
                    if (tempUrl) {
                        src = tempUrl;
                    }
                }
            } catch (error) {
                console.log("error parsing animationData >>>>", animationData);
            }
        }

        //console.log("src", src)
        src = this.setCdnUrl(src);
        const cardStyle = this.getCss(data, null, {});
        const className = Utils.get(data, "props.title");
        let width = parseInt(Utils.get(data, "props.width"));
        let height = parseInt(Utils.get(data, "props.height"));
        const objectFit = Utils.get(data, "props.objectFit");
        const alt = !Utils.empty(Utils.get(data, "props.alt")) ? Utils.get(data, "props.alt") : null;

        let imgStyle = { width: "100%", "verticalAlign": "bottom" };

        if (objectFit) {
            imgStyle.objectFit = objectFit;
            imgStyle.height = "100%";
        }
        const autoPlay = Utils.get(data, "props.autoplay", "").toLowerCase() === "true";
        const loop = Utils.get(data, "props.loop", "").toLowerCase() === "true";

        if (typeof animationData === 'string' && animationData.includes(".json")) {
            animationData = st[`lottie_${cardId}`];
        }

        return (
            <Div key={key} style={{ ...cardStyle, ...{ "overflow": "hidden" } }} className={className} datatitle={Utils.get(data, "title")}>
                <Lottie
                    height={height}
                    width={width}
                    options={{
                        animationData: animationData,
                        loop: loop,
                        autoplay: autoPlay,
                        path: src,
                        //path: '/images/lottie/chat_dialog.json',
                        assetsPath: "",
                    }}
                // speed={st.isPlaying ? 1 : 0}
                />
            </Div>
        );
    }

    renderCard_button(key, data) {
        const pr = this.props;

        const title = Utils.get(data, "props.title", "");
        let url = Utils.get(data, "props.url");
        const target = Utils.get(data, "props.target");
        const type = Utils.get(data, "props.type");
        const name = Utils.get(data, "props.name");
        const buttonType = Utils.get(data, "props.buttonType");
        const validate = Utils.get(data, "props.validate", "false") == "true";
        let disabled = Utils.get(data, "props.disabled", "false") == "true";
        if (disabled == "userLoaded") {
            disabled = pr.userLoaded;
        }
        const subtitle = Utils.get(data, "props.subtitle");
        const subtitleColor = Utils.get(data, "props.subtitleColor");
        const enabled = Utils.get(data, "props.enabled", "false");

        if (validate && !disabled) {
            let out = {};
            const rules = [
                { type: "accept", field: "form1_field^accept" },
            ];
            disabled = !Validate.valid(rules, out, this.state);
        }

        //console.log("Button type", type, data);
        const color = Utils.get(data, "props.color", "pinkGradient");
        const formId = Utils.get(data, "props.formId", 9999); // NOTE: figure out how to get this a formId
        const successMessage = Utils.get(data, "props.successMessage", "Thanks for submitting");
        const successTitle = Utils.get(data, "props.successTitle", "Success!");
        const successRedirectUrl = Utils.get(data, "props.successRedirectUrl", "");
        let apiErrorStateVarName = Utils.get(data, "props.apiErrorStateVarName", "");
        if (!Utils.empty(apiErrorStateVarName)) {
            apiErrorStateVarName = `form${formId}_${apiErrorStateVarName}`;
        }

        const gaSubmitEvent = Utils.get(data, "props.gaSubmitEvent", "application_submission");

        const cardStyle = this.getCss(data);

        let clickHandler = null;
        if (false) {
            clickHandler = this.handleMoonpayClick.bind(this);
        } else if (type == "modal_doc") {
            clickHandler = this.showModal.bind(this, "showDoc_" + Utils.get(data, "docName", "leaderboard_rules"));
        } else if (type == "toggle") {
            const num = 1;
            const formName = Utils.get(data, "props.form_name", 'form1');
            const field = formName + '_' + name;
            let value = Utils.get(data, "props.value");
            clickHandler = this.handleToggleRadioValue.bind(this, field, value, data['props']['value'])
        } else if (type == "form") {
            clickHandler = this.handleFormSubmit.bind(this, formId, successMessage, successTitle, successRedirectUrl, gaSubmitEvent, url, apiErrorStateVarName);
        } else if (type == "hackModal" || type == "buttonModalShow") {
            const modalName = Utils.get(data, "props.modalName", "show_modal");
            const modalShowVal = Utils.get(data, "props.modalShowVal", "optionModal");
            clickHandler = () => {
                console.log("hackModalClose OPEN >>>>", modalName, modalShowVal);
                let tempState = {};
                tempState["form1_" + modalName] = modalShowVal;
                this.setState(tempState);
            };
        } else if (type == "hackModalClose" || type == "buttonModalHide") {
            const modalHideVal = Utils.get(data, "props.modalHideVal", "");
            const modalName = Utils.get(data, "props.modalName", "show_modal");

            clickHandler = () => {
                console.log("hackModalClose CLOSE >>>>", this.state.form1_show_modal);
                setTimeout(() => {
                    let tempState = {};
                    tempState["form1_" + modalName] = modalHideVal;
                    this.setState(tempState);
                }, 500);

                // console.log("hackModalClose CLOSE >>>>", this.state.form1_show_modal);
            };
        } else if (type == "mail") {
            // TODO: we want a generic function to replace all of the dynamic values
            url = url.replace("{val1}", Utils.get(this.state, "form1_my_field_1"));
            clickHandler = () => {
                Utils.gotoRoute(pr, url);
            };
        } else if (url) {
            // TODO: Make this a replace for any fields
            if (url.includes("{field^instagallery.0.id}")) {
                url = url.replace("{field^instagallery.0.id}", Utils.get(this.state, "field^instagallery.0.id"));
            } else if (Utils.get(this.state, url)) {
                url = Utils.get(this.state, url);
            }

            clickHandler = () => {
                this.goTo(url, target);
            };
        }

        if (enabled == "userLoaded" && !pr.userLoaded) {
            return (
                <Spinner />
            )
        }
        return <Div key={key}>
            <Button
                onClick={clickHandler}
                style={cardStyle}
                color={color}
                type={buttonType}
                disabled={disabled}
                subtitle={subtitle}
                subtitleColor={subtitleColor}
                className={title}
                noBorder
            >
                {Utils.get(data, 'props.title')}
            </Button>
            {this.renderFormErrors(key)}
            {this.state.showFormModal ?
                <Modal
                    screen={this.props.screen}
                    onRequestClose={() => { this.setState({ showFormModal: false }) }}
                    className="biddingModal"
                    color="indigo"
                    title={this.state.successTitle}
                    hideCloseButton={this.state.successRedirectUrl.length > 0}
                >
                    <Div styler={{ height: 200, width: "100%", borderRadius: 12, overflow: "hidden", }}>
                        {this.state.successMessage}
                    </Div>

                    {this.state.successRedirectUrl.length > 0 ?
                        <Button
                            style={{ marginTop: 20 }}
                            onClick={this.goTo.bind(this, this.state.successRedirectUrl)}
                        >
                            Continue
                        </Button>
                        :
                        null
                    }
                </Modal>
                :
                null
            }
        </Div>
    }

    renderCard_datetime(key, data) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const cardStyle = this.getCss(data);
        const param = Utils.get(data, "props.param");
        const type = Utils.get(data, "props.type");
        const event = Utils.get(st, "field^event");
        const eventDate = Utils.get(st, "field^event.event_date");
        const eventStartTime = Utils.get(st, "field^event.event_start_time");
        const timezone = Utils.get(st, "field^event.event_timezone", "");
        let startTime = "";
        let amPm = "";
        let placement = "";

        let outStr = "Date / Time TBA";

        if (!Utils.empty(eventDate) && type == "Format1MDTZ") {
            const parts = eventDate.split("-");
            let month = moment(parts[1], 'M').format("MMMM");
            let day = parseInt(parts[2]);
            let placementArray = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
            placement = placementArray[day % 10];

            outStr = `${month} ${day}${placement}`;

            if (Utils.get(event, "event_start_time")) {
                const timeArray = eventStartTime.split(":");
                const hour = timeArray[0];
                const minutes = timeArray[1];

                startTime = `${parseInt(hour) % 12}:${minutes}`;

                amPm = parseInt(eventStartTime) > 12 ? "PM" : "AM";
                outStr += `, ${startTime} ${amPm} ${timezone}`;
            } else {
                outStr += `, Time TBA`;
            }
        }

        return (
            <Div key={key} style={cardStyle} className={Utils.get(data, "className")}>
                {outStr}
            </Div>
        )
    }

    renderCard_modaldoc(key, data) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const docName = Utils.get(data, "props.docName");
        const showName = "showDoc_" + docName;

        const cardStyle = this.getCss(data);
        const docHtml = Utils.get(st, "docs." + docName + ".body", "");
        const docTitle = Utils.get(st, "docs." + docName + ".title", "");

        return (
            <Div>
                {st[showName] ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeModal.bind(this, showName)}
                        className="biddingModal"
                        color="purple"
                        title={<Div style={{ fontSize: 44 }}>{docTitle}</Div>}
                    >
                        <Div style={{ justifyContent: "center", minHeight: 500, width: "100%", borderRadius: 12, overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Div dangerouslySetInnerHTML={{ __html: docHtml }}>
                            </Div>
                        </Div>
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    renderCard_modalpost(key, data) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;
        const title = Utils.get(data, "props.title");
        const name = Utils.get(data, "props.name");
        const formId = Utils.get(data, "props.formId", 1);
        const hideCloseButton = Utils.get(data, "props.hideCloseButton") ? true : false;

        const showName = `form${formId}_${name}`;

        const cardStyle = this.getCss(data);
        const postId = Utils.get(data, "props.postId");
        const modalColor = Utils.get(data, "props.modalColor", "indigo");
        const post = Utils.get(st, 'includedPosts.' + postId + '.json_data.cards', "");
        //console.log("POST", post);
        let loopState = {};
        return (
            <Div>
                {st[showName] ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.closeModal.bind(this, showName)}
                        className={`modalPost modalPost-${postId}`}
                        color={modalColor}
                        title={<Div style={{ fontSize: 44 }}>{title}</Div>}
                        hideCloseButton={hideCloseButton}
                    >
                        <Div style={{ ...{ justifyContent: "center", width: "100%", borderRadius: 12, overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center" }, ...cardStyle }}>
                            {this.renderCardLoop(post, `modalPost-${postId}`, this.state)}
                        </Div>
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    renderCard_doc(key, data) {
        const pr = this.props;
        const st = this.state;

        const docName = Utils.get(data, "props.docName");
        const body = Utils.get(st, `docs.${docName}.body`);
        const cardStyle = this.getCss(data);

        return (
            <Div style={cardStyle} key={key}>
                <Doc
                    screen={pr.screen}
                    user={pr.user}
                    body={body}
                />
            </Div>
        );
    }

    renderCard_countdown(key, data) {
        const pr = this.props;
        const st = this.state;

        let cardStyle = this.getCss(data);

        const name = Utils.get(data, 'props.name', "");

        return (
            <Div key={key} className="cmsCountdownContainer">
                <Countdown
                    style={cardStyle}
                    eventTimeStamp={st.eventTimeStamp}
                    name={name}
                />
            </Div>
        )
    }

    renderCard_select(key, data) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const text = Utils.get(data, "props.description");
        if (!text) {
            return null;
        }
        const name = Utils.get(data, "props.name");
        let field = "param_" + name;

        const formName = Utils.get(data, "props.form_name", 'form1');
        if (!Utils.empty(formName)) {
            field = formName + '_' + name;
        }
        // TODO: Allow for default or no selection

        const cardStyle = this.getCss(data);
        const bullets = text.split("\n");
        const itemStyle = {};
        // TODO: Add value with |

        const bulletsHtml = bullets.map((bulletItem, bulletIndex) => {
            return (
                <option
                    key={`select-option-${key}-${bulletIndex}`}
                    style={itemStyle}>
                    {bulletItem}
                </option>
            );
        });

        return (
            <Div>
                <select
                    value={this.state[field]}
                    onChange={(event) => {
                        let tempState = {};
                        tempState[field] = event.target.value;
                        this.setState(tempState);
                        //console.log("param_"+name, event.target.value);
                    }}
                    style={sty.selectStyle}
                >
                    {bulletsHtml}
                </select>
            </Div>
        );
    }

    getSelectedItem() {
        const st = this.state;
        const selectorParam = "form1_collectible_container";
        let item = Utils.get(st, "field^item");
        if (selectorParam && st[selectorParam] > 0) {
            for (var idx in st['field^items']) {
                const curItem = st['field^items'][idx];
                if (curItem['id'] == st[selectorParam]) {
                    item = curItem;
                }
            }
        }
        return item;
    }

    renderCard_follow(key, data) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const modalHeight = 350;

        const quantity = Utils.get(this.state, "param_ticket_quantity", 1)

        const cardStyle = this.getCss(data);
        const cardId = Utils.get(data, "id");

        const quantityString = quantity == 1 ? "a Collectible" : quantity + " Collectibles"

        const artistId = Utils.get(st, "field^artist.id", 77);
        // const customerIsFollowing = Utils.get(st, "field^artist.customer_is_following");

        let followButtonCss = { color: Colors.indigo, border: "2px solid white", backgroundColor: "white" };

        if (st.customerIsFollowing) {
            followButtonCss = { color: Colors.white, border: "2px solid white", backgroundColor: "transparent" };
        }

        const titleFollowing = Utils.get(data, "props.titleFollowing", "Following");
        const titleNotFollowing = Utils.get(data, "props.titleNotFollowing", "Follow");
        const subtitleFollowing = Utils.get(data, "props.subtitleFollowing");
        const subtitleNotFollowing = Utils.get(data, "props.subtitleNotFollowing");
        const subtitleColor = Utils.get(data, "props.subtitleColor");
        const followType = Utils.get(data, "props.type");
        const gotoOnClick = followType == 3;

        const newKey = `${key}-followCard-${cardId}`;
        return (
            <Div className={`cardFollow followClass-${cardId}`} key={newKey}>
                <Button
                    onClick={this.handleFollowClick.bind(this, artistId, followType, cardId)}
                    style={{ ...followButtonCss, ...cardStyle }}
                    subtitle={st.customerIsFollowing ? subtitleFollowing : subtitleNotFollowing}
                    subtitleColor={subtitleColor}
                >
                    {st.customerIsFollowing && !gotoOnClick ? titleFollowing : titleNotFollowing}
                </Button>

                {Utils.get(st, `showRegisterFlowFollowModal_${cardId}`) ?
                    <Modal
                        onRequestClose={this.closeRegisterFlowModal.bind(this, cardId)}
                        className={`followCardModal-${cardId}`}
                        screen={pr.screen}
                    >
                        <WebappLoginRegister
                            isLogin={false}
                            screen={pr.screen}
                            user={pr.user}
                            history={pr.history}
                            style={{ minHeight: 0, width: "100%", maxWidth: 400 }}
                            setUser={pr.setUser.bind(this)}
                            onLoginPhone={this.onLoginPhone.bind(this)}
                            onLoginEmail={this.onLoginEmailFollow.bind(this, this.handleFollowClick.bind(this, artistId, followType, cardId), cardId)}
                            onSignUpPhone={this.onSignUpPhone.bind(this)}
                            onConfirmVerificationCodeLogin={this.onConfirmVerificationCodeLoginFollow.bind(this, this.handleFollowClick.bind(this, artistId, followType, cardId), cardId)}
                            onConfirmVerificationCodeSignUp={this.onConfirmVerificationCodeSignUpFollow.bind(this, this.handleFollowClick.bind(this, artistId, followType, cardId), cardId)}
                            isModalForGoogle
                        />
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }



    renderCard_paypal(key, data) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        const modalHeight = 350;

        const quantity = Utils.get(this.state, "param_ticket_quantity", 1)

        const cardStyle = this.getCss(data);
        const sharableLink = Utils.get(data, "props.share_to_win_unique_link", "") + "&refer=" + Utils.get(pr, "user.id") + "_3504699855";
        //item = st['field^items'][2];
        const item = this.getSelectedItem();
        //console.log("ITEM", item);
        const hasStripeRef = Utils.get(item, "stripe_ref_num", "").length > 0 ? true : false;
        const soldOut = Utils.get(item, "total_prints", 1) == -1;
        const soldOutText = Utils.get(item, "sale_sold_out_text", "Collectible sold out.");
        const cardId = Utils.get(data, "id");
        //console.log("PAYPAL for:", cardId);

        //const quantityString = quantity == 1 ? "a Yung Gravy Pass" : quantity + " Yung Gravy Passes"
        const quantityString = quantity == 1 ? "a Collectible" : quantity + " Collectibles"

        return (
            <Div className="cardPayPal" key={key}>
                <Button
                    onClick={this.handleBuyPayPalClick.bind(this, cardId, soldOut, hasStripeRef, item)}
                    style={cardStyle}
                    color="pinkGradient"
                    disabled={Utils.get(item, 'initial_price') ? false : true}
                >
                    {Utils.get(data, 'props.title')}
                </Button>
                {/* TODO BETTER: if NOT desktop and NOT in safari, display message instead of pr.screen.mobile */}
                {/* NOTE: Venmo is always avail on desktop, but on iOS it's only avail on Safari */}
                {false && Utils.get(st.userDevice, "device.type") != "desktop" && !Utils.get(st.userDevice, "client.name").includes("Safari") ?
                    <Div className="venmoSafariMessage" style={sty.venmoSafariMessage}>
                        <Image
                            src="https://dhwk6gixvyfl3.cloudfront.net/upload_d0f1a7053c1e07fcff41c676456f7945.png"
                            style={{ width: 20, marginBottom: -5, marginRight: 4, }}
                        />
                        <Span>Venmo available only in the Safari mobile browser&nbsp;</Span>
                        {false ?
                            <Button
                                type="text"
                                isSpan
                                style={{ color: Colors.magenta }}
                                // onClick={() => {window.open(location.href.replace("https://", "safari-https://"))}}
                                // TODO: use this link: <a href="x-web-search://?Afterparty.com+guardians">x-web-search://AP</a>
                                onClick={() => { window.open(location.href.replace("https://", "x-web-search://")) }}
                            >
                                Safari browser
                            </Button>
                            :
                            null
                        }

                    </Div>
                    :
                    null
                }

                {st.showRegisterFlowModal ?
                    <Modal
                        onRequestClose={this.closeRegisterFlowModal.bind(this)}
                        className="learnMoreModal"
                        screen={pr.screen}
                    >
                        <WebappLoginRegister
                            isLogin={false}
                            screen={pr.screen}
                            user={pr.user}
                            history={pr.history}
                            style={{ minHeight: 0, width: "100%", maxWidth: 400 }}
                            setUser={pr.setUser.bind(this)}
                            onLoginPhone={this.onLoginPhone.bind(this)}
                            onLoginEmail={this.onLoginEmail.bind(this, false, false)}
                            onSignUpPhone={this.onSignUpPhone.bind(this)}
                            onConfirmVerificationCodeLogin={this.onConfirmVerificationCodeLogin.bind(this)}
                            onConfirmVerificationCodeSignUp={this.onConfirmVerificationCodeSignUp.bind(this)}
                            triggerStripeIntent={this.triggerStripeIntent.bind(this, soldOut, hasStripeRef, item)}
                            isModalForGoogle
                        />
                    </Modal>
                    :
                    null
                }

                {/* TODO DELETE */}
                {st.showRegisterFlowModal && false ?
                    <RegisterFlow
                        user={pr.user}
                        screen={pr.screen}
                        history={pr.history}
                        //usernameModalTitle={<Div>Create an account to RSVP for the <br/>{eventTitleText} Event</Div>}
                        //successModalMessage={<Div>Already have {pr.eventItem.title} social token?{pr.screen.mobile ? null : <br/>}</Div>}
                        registerModalBody={st.registerModalBody}
                        showRegisterFlowModal={st.showRegisterFlowModal}
                        eventItem={pr.eventItem}
                        isPhoneInput
                        successMessageData={Utils.get(pr.eventItem, "json_data.successBody", {})}
                        setUser={pr.setUser.bind(this)}
                        onRequestRegisterModalClose={this.closeRegisterFlowModal.bind(this)}
                        //handleSetWallet={this.handleSetWallet.bind(this)}
                        setRegisteredSuccess={this.showPayPalModal.bind(this)}
                    />
                    :
                    null
                }


                {st.showPayPalModal && st.paypalCardId == cardId ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={() => {
                            this.closeModal('showPayPalModal')
                            // note: Need to clear here to avoid potentially leak on previous stripe client secret which would side effects with the amount and confirmation
                            this.setState({
                                stripeInfo: null,
                                stripeError: null,
                            })
                        }}
                        className="biddingModal"
                        color="indigoLight"
                        title={`@${Utils.get(item, "title")}`}
                    >
                        <Div style={{ height: modalHeight, width: "100%", borderRadius: 12, marginTop: 20, display: "flex", flexDirection: "column", overflow: 'auto' }}>
                            {soldOut ?
                                <Div style={{ textAlign: "center", fontSize: 36, fontWeight: "bold" }}>{this.plainTextToRichTextArray(soldOutText)}</Div>
                                :
                                null
                            }
                            {!soldOut && st.isCreditCard && st.stripeError && (
                                <Div style={{ margin: "14px 0" }}>{st.stripeError}</Div>
                            )}
                            {!soldOut && st.isCreditCard && st.stripeInfo ?
                                <>
                                    <StripePayment
                                        titleText={" "}
                                        style={{ width: 300, background: "none", }}
                                        stripeMode="test"
                                        stripeInfo={st.stripeInfo}
                                        stripePaymentCallback={this.stripePaymentCallback.bind(this)}
                                        onRequestClose={this.closeModal.bind(this, 'showPayPalModal')}
                                        quantity={quantity}
                                        collectableId={Utils.get(this.state, "form1_collectible_container")}
                                        selectedItem={item}
                                        stripeV1Component={
                                            hasStripeRef ?
                                                // note StripeV1 contains the two original buttons
                                                <StripeV1SingleButton
                                                    state={this.state}
                                                    styles={this.styles}
                                                    quantity={quantity}
                                                    collectableId={Utils.get(this.state, "form1_collectible_container")}
                                                />
                                                : null
                                        }
                                        version="V3"
                                    />
                                    <Div style={{ color: Colors.white, margin: "14px 0", textAlign: "center" }}>Other Ways to Pay</Div>
                                </>
                                :
                                null
                            }

                            {!soldOut ?
                                <Div className="paypalContainer" id={`paypal-button-container-${cardId}`}>
                                </Div>
                                :
                                null
                            }
                        </Div>
                    </Modal>
                    :
                    null
                }
                {st.showPayPalSuccessModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={() => {
                            this.closeModal('showPayPalSuccessModal')
                            // note: Need to clear here to avoid potentially leak on previous stripe client secret which would side effects with the amount and confirmation
                            this.setState({
                                stripeInfo: null,
                                stripeError: null,
                            })
                        }}
                        className="biddingModal"
                        color="indigoLight"
                        title={<Div style={{ fontSize: 44 }}>Success!</Div>}
                    >
                        <Div style={{
                            textAlign: "center",
                            justifyContent: "center",
                            width: "100%",
                            borderRadius: 12,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: 370,
                        }}
                        >
                            <Div style={{ fontSize: 18, marginBottom: 8, marginTop: "auto" }}>
                                You've purchased {quantityString}.  Share the link below to work your way up the leaderboard
                            </Div>

                            {/* <Div style={{fontSize: 28, fontWeight: 700, marginBottom: "auto"}}>
                                Yung Gravy pass!
                            </Div> */}

                            <Input
                                inputType="placeholderLabel"
                                placeholder="Shareable Link"
                                value={sharableLink}
                                placeholderLabelStyle={{ width: "100%", marginTop: 20, marginBottom: 10, maxWidth: 350, marginLeft: "auto", marginRight: "auto" }}
                                readOnly
                            />

                            <Button
                                style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10, marginBottom: 20 }}
                                size="small"
                                onClick={this.viewLeaderboard.bind(this)}
                                color="pinkGradient"
                            >
                                {Utils.get(st.cardsDict, "share_to_win_view_leaderboard_button.props.description", "View Leaderboard")}
                            </Button>

                            <Div style={{ fontSize: 14, marginBottom: 8, marginTop: "auto", maxWidth: 300, marginBottom: 20 }}>
                                {Utils.get(data, "props.share_to_win_qr_code_message", "")}
                            </Div>
                            <Button
                                color="pinkGradient"
                                onClick={this.goTo.bind(this, "mywallet")}
                            >
                                View In Your Wallet
                            </Button>
                        </Div>
                    </Modal>
                    :
                    null
                }
                {st.showPayPalSuccessModal2 ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={() => {
                            this.closeModal('showPayPalSuccessModal2')
                            // note: Need to clear here to avoid potentially leak on previous stripe client secret which would side effects with the amount and confirmation
                            this.setState({
                                stripeInfo: null,
                                stripeError: null,
                            })
                        }}
                        className="biddingModal"
                        color="indigoLight"
                    >
                        <Div style={{
                            textAlign: "center",
                            justifyContent: "center",
                            width: "100%",
                            borderRadius: 12,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: 370,
                        }}
                        >
                            <Div>
                                <video
                                    src={Utils.get(item, 'animation_url')}
                                    autoPlay={true}
                                    muted={true}
                                    playsInline={true}
                                    poster={Utils.get(item, 'image_url')}
                                    controls={false}
                                    style={{ padding: 12, width: 256 }}
                                    loop={true}
                                ></video>
                            </Div>
                            <Div style={{ fontSize: 28, color: Colors.neonGreen, marginBottom: 8, marginTop: "auto" }}>
                                Congrats on your new collectible{quantityString > 1 ? <Span>s</Span> : null}!
                            </Div>
                            <Div>
                                For every collectible that drops, 10 collectors will be given a Creator Callout. Enter a message below -- if you are chosen , the creator will respond!
                            </Div>
                            {/* <Div style={{fontSize: 28, fontWeight: 700, marginBottom: "auto"}}>
                                Yung Gravy pass!
                            </Div> */}

                            <Input
                                inputType="placeholderLabel"
                                placeholder="Enter your message here"
                                value={this.state.creatorMessage}
                                onChange={this.changeVal.bind(this, "creatorMessage")}
                                placeholderLabelStyle={{ width: "100%", marginTop: 20, marginBottom: 10, maxWidth: 350, marginLeft: "auto", marginRight: "auto" }}
                            />

                            <Button
                                color="pinkGradient"
                                onClick={this.handleSubmitCreatorMessage.bind(this)}
                            >
                                Submit & View in Wallet
                            </Button>
                        </Div>
                    </Modal>
                    :
                    null
                }

            </Div>
        );
    }

    stripePaymentCallback(stripeResponse) {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        console.log('stripePaymentCallback>>>state', st, '\n stripeResponse:', stripeResponse)

        this.setState({ showPayPalModal: false, showPayPalSuccessModal2: true, form1_show_modal: "" });
        const quantity = Utils.get(this.state, "param_ticket_quantity", 1)
        const pricePerTicket = 1;
        const artist_name = Utils.get(st, "field^artist_name") || "collectible_stripe";
        // TODO: onsale_event_id : ARTISTNAME, artist_name -- double check qty and value
        // event_type: 'afterparty_livestream' <-- paypal card default
        const value = quantity * pricePerTicket;

        const eventProperties = {
            userID: 'afterparty-' + Utils.get(this.props, "user.id", ''), //string minimum of 5 characters
            value: value,
            currency: 'USD',
            quantity: quantity,
            paymentsource: 'stripe'
        };
        amplitude.track('payment_client_payment_completed', eventProperties);
        const gtmData = {
            'event': 'ticket_purchase',
            'purchase.user_id': Utils.get(pr, "user.id"),
            'purchase.user_email': Utils.get(pr, "user.email"),
            'purchase.user_phone': Utils.get(pr, "user.primary_phone1"),
            'onsale_event_id': 'collectible_stripe',
            'quantity': quantity,
            'value': quantity * pricePerTicket, 'currency': 'USD', 'artist': artist_name
        };
        //console.log("gtmData", gtmData);
        window.dataLayer.push(gtmData);

        /*
        this.setState({
            showPaymentModalNextButton: true,
        });
        */
    }

    // StripeV4
    renderCard_stripe(key, data) {
        // mostly taken from renderCard_paypal home of StripeV3
        const pr = this.props;
        const st = this.state;
        const cardStyle = this.getCss(data);
        const user = Utils.get(pr, "user");
        const formId = Utils.get(data, "props.formId", 1);
        const title = Utils.get(data, 'props.title');
        const artistId = Utils.get(st, "field^artist.id", 77);
        const successPostId = Utils.get(data, "props.successPostId")
        let isGA;

        // > 2 when the quantity dropdown is selected
        const quantity = Utils.get(st, "param_ticket_quantity", 1);
        const selectedItem = this.getSelectedItem();

        // same as Utils.get(this.state, "form1_collectible_container") -> '322'
        const collectibleId = selectedItem ? selectedItem.id : null; // -> 322

        // same as const amount = Utils.get(selectedItem, 'param^price_per_ticket');
        const amount = Utils.get(selectedItem, 'initial_price');

        const paymentType = "collectable"

        // for success modal
        const quantityString = quantity == 1 ? "a Collectible" : quantity + " Collectibles"

        const cardId = Utils.get(data, "id");

        const soldOut = Utils._int(amount) === 0 || Utils.get(selectedItem, "total_prints", 1) == -1;
        // GA / Freemium exception for UI/UX
        const saleTitle = Utils.get(selectedItem, 'sale_title')
        if (saleTitle) {
            isGA = saleTitle.toLowerCase().includes("ga") ? title : false
        }
        const defaultSoldOutText = isGA || "Collectible sold out."
        const soldOutText = Utils.get(selectedItem, "sale_sold_out_text", defaultSoldOutText);


        // console.log('renderCard_stripe>data', data,
        //     '\n pr', pr,
        //     '\n st', st,
        //     '\n quantity', quantity,
        //     '\n selectedItem', selectedItem,
        //     '\n collectibleId', collectibleId,
        //     '\n soldOut', soldOut,
        //     '\n amount', amount
        // )

        const openPaymentsV2 = () => {
            this.setState({
                paypalCardId: cardId,
                showPaymentsV2Modal: true,
            });
        }

        const handleOpenPaymentsV2 = (formId) => {
            if (Utils.get(this.props, "user.id")) {
                // NOTE: do form Validation
                if (formId) {
                    let data = this.getFormVariables(formId);
                    let success = data['success'];
                    delete data['success'];

                    if (success) {
                        openPaymentsV2();
                    } else {
                        return;
                    }
                } else if (!soldOut) {
                    openPaymentsV2();
                }
            } else {
                this.setState({
                    paypalCardId: cardId,
                    showRegisterFlowModal: true,
                });
            }
        }

        const genericModalSuccess = document.location.pathname.indexOf("first_time_offer") == -1 && document.location.pathname.indexOf("ai_chat_payment_options") == -1 ? true : false;

        return (
            <Div key={key}>
                <Button
                    onClick={() => { handleOpenPaymentsV2(formId ?? undefined) }}
                    style={cardStyle}
                    color="pinkGradient"
                    disabled={soldOut}
                >
                    {soldOut ? soldOutText : title}
                </Button>
                {this.renderFormErrors(key)}
                {st.showPaymentsV2Modal && (
                    <PaymentsV2
                        metadata={{
                            user,
                            artist: Utils.get(st, "field^artist"),

                        }}
                        modalProps={{
                            screen: this.props.screen,
                            isDragon: false,
                            onRequestClose: () => {
                                this.closeModal('showPaymentsV2Modal')
                            },
                            title: `@${Utils.get(selectedItem, "title")}`,
                        }}
                        stripeProps={{
                            showButtons: true,
                            quantity,
                            collectibleId,
                            paymentType,
                            formData: this.getFormVariables(formId, true),
                            // will override the default paymentSuccess within StripePayment
                            paymentSuccess: (paymentIntent) => {
                                this.closeModal('showPaymentsV2Modal');
                                if (Utils.empty(successPostId)) {
                                    this.setState({
                                        showPaymentsV2SuccessModal: true,
                                    })
                                } else {
                                    // /api/v1/afterparty_shoutout_listing/100931
                                    this.setState({
                                        shoutoutId: 100931,
                                        showPaymentsV2SuccessModal: true,
                                    });
                                }
                            },
                            // enables the custom success modal for PaymentsV2
                            // set as false to disable and just have it default to paymentMethodSuccess
                            // note, it will still call paymentMethodSuccess regardless
                            paymentMethodSuccessModal: false,
                            // method called after a successful payment via the payment method selected
                            // this differs from paymentSuccess because the user is using a payment method
                            // they already stored where as paymentSuccess is starting a new payment method
                            paymentMethodSuccess: (paymentIntent) => {
                                this.closeModal('showPaymentsV2Modal')
                                this.setState({
                                    showPaymentsV2SuccessModal: true,
                                })
                            },
                            onRequestClose: () => {
                                console.log('Stripe on close');
                                this.closeModal('showPaymentsV2Modal')
                            },
                        }}
                        paypalProps={{
                            showButtons: true,
                            cardId,
                            // paypal seems to support float as string or number e.g. "2.00" or 3.99
                            // require for paypal to work
                            amount,
                            quantity,
                            paymentSuccess: () => {
                                this.closeModal('showPaymentsV2Modal')
                                this.setState({
                                    showPaymentsV2SuccessModal: true,
                                })
                            },
                            paymentType,
                            collectibleId,
                            formData: this.getFormVariables(formId, true),
                        }}
                    />
                )}
                {/* based on showPayPalSuccessModal2 */}
                {st.showPaymentsV2SuccessModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={() => {
                            this.closeModal('showPaymentsV2SuccessModal')
                            // clears query strings from stripe redirect
                            cleanUrlQueryStrings()
                        }}
                        className="biddingModal"
                        color="indigoLight"
                    >
                        <Div style={{
                            textAlign: "center",
                            justifyContent: "center",
                            width: "100%",
                            borderRadius: 12,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: 370,
                        }}
                        >
                            {genericModalSuccess ?
                                Utils.empty(successPostId) ?
                                    <Div className="showPaymentsV2SuccessModalGeneric">
                                        <Div>
                                            <video
                                                src={Utils.get(selectedItem, 'animation_url')}
                                                autoPlay
                                                muted={true}
                                                playsInline={true}
                                                poster={Utils.get(selectedItem, 'image_url')}
                                                controls={false}
                                                style={{ padding: 12, width: 256 }}
                                                loop={true}
                                            ></video>
                                        </Div>
                                        <Div style={{ fontSize: 28, color: Colors.neonGreen, marginBottom: 8, marginTop: "auto" }}>
                                            Congrats on your new collectible{quantityString > 1 ? <Span>s</Span> : null}!!
                                        </Div>
                                        <Div>
                                            For every collectible that drops, 10 collectors will be given a Creator Callout. Enter a message below -- if you are chosen , the creator will respond!
                                        </Div>
                                        <Div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
                                            <Input
                                                inputType="placeholderLabel"
                                                placeholder="Enter your message here"
                                                value={this.state.creatorMessage}
                                                onChange={this.changeVal.bind(this, "creatorMessage")}
                                                placeholderLabelStyle={{ width: "100%", marginTop: 20, marginBottom: 10, maxWidth: 350, marginLeft: "auto", marginRight: "auto" }}
                                            />

                                            <Button
                                                color="pinkGradient"
                                                onClick={this.handleSubmitCreatorMessage.bind(this)}
                                            >
                                                Submit & View in Wallet
                                            </Button>
                                        </Div>
                                    </Div>
                                    :
                                    <Div className="showPaymentsV2SuccessModalAiChat">
                                        <Div style={{ fontSize: 28, color: "white", marginBottom: 8, marginTop: "auto" }}>
                                            <Div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>
                                                <Image
                                                    className="envelopeEmail"
                                                    src={envelopeEmail}
                                                    width={48}
                                                />
                                                <Div style={{ marginBottom: 8 }}>Your Shoutout Is On The Way</Div>
                                                <Div style={{ fontSize: 12, fontWeight: 400 }}>We’ll send an email to {Utils.get(pr, "user.email")} with a link to your custom message when generation is complete</Div>
                                                <Div style={{ fontSize: 18, marginTop: 40 }}>
                                                    Want More Shoutouts?
                                                </Div>
                                                <Button
                                                    id="shoutouts_share_generatemore"
                                                    color="redGradient"
                                                    style={{ marginLeft: "auto", marginRight: "auto", marginTop: 16 }}
                                                    onClick={() => {
                                                        // this.closeModal('showPaymentsV2SuccessModal')
                                                        // cleanUrlQueryStrings()
                                                        Utils.gotoRoute(pr, `p/shoutouts_form/artist_id-${artistId}`)
                                                    }}
                                                >
                                                    Create More Shoutouts
                                                </Button>
                                            </Div>
                                        </Div>
                                    </Div>

                                :
                                <Div className="showPaymentsV2SuccessModalAiChat">
                                    <Div style={{ fontSize: 28, color: "white", marginBottom: 8, marginTop: "auto" }}>
                                        Success
                                    </Div>
                                    <Div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>
                                        Thank you for your purchase. You can use these Tokens to chat with any creator on Afterparty AI!
                                    </Div>

                                    <Div style={{ margin: 20, display: "flex", justifyContent: "center", }}>
                                        <Button
                                            color="white"
                                            onClick={Utils.gotoRoute.bind(this, pr, `/private/aichat`)}
                                        >
                                            Back to Chat
                                        </Button>
                                    </Div>
                                </Div>
                            }
                        </Div>
                    </Modal>
                    :
                    null
                }
                {st.showPaymentsV2FailureModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={() => {
                            this.closeModal('showPaymentsV2FailureModal')
                            // clears query strings from stripe redirect
                            cleanUrlQueryStrings()
                        }}
                        className="biddingModal"
                        color="indigoLight"
                    >
                        <Div style={{
                            textAlign: "center",
                            justifyContent: "center",
                            width: "100%",
                            borderRadius: 12,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: 370,
                        }}
                        >
                            <Div>
                                <video
                                    src={Utils.get(selectedItem, 'animation_url')}
                                    autoPlay
                                    muted={true}
                                    playsInline={true}
                                    poster={Utils.get(selectedItem, 'image_url')}
                                    controls={false}
                                    style={{ padding: 12, width: 256 }}
                                    loop={true}
                                ></video>
                            </Div>
                            <Div style={{ fontSize: 28, color: Colors.orange, marginBottom: 8, marginTop: "auto" }}>
                                We're sorry. Something went wrong with your payment. Please try again.
                            </Div>
                        </Div>
                    </Modal>
                    :
                    null
                }
                {st.showRegisterFlowModal ?
                    <Modal
                        onRequestClose={this.closeRegisterFlowModal.bind(this)}
                        className="stripeRegisterModal"
                        screen={pr.screen}
                    >
                        <WebappLoginRegister
                            isLogin={false}
                            screen={pr.screen}
                            user={pr.user}
                            style={{ minHeight: 0, width: "100%", maxWidth: 400 }}
                            setUser={pr.setUser.bind(this)}
                            onLoginPhone={this.onLoginPhone.bind(this)}
                            onLoginEmail={this.onLoginEmail.bind(this, false, false)}
                            onSignUpPhone={this.onSignUpPhone.bind(this)}
                            onConfirmVerificationCodeLogin={this.onConfirmVerificationCodeLogin.bind(this)}
                            onConfirmVerificationCodeSignUp={this.onConfirmVerificationCodeSignUp.bind(this)}
                            triggerStripeIntent={openPaymentsV2}
                            isModalForGoogle
                        />
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    renderCard_moonpay(key, data) {
        const contractAddress = Utils.get(this.state, "moonpayInfo.contractAddress"); //"0xA1A9dFab98C5F692119686Aff5f5Bd993A8B5330";
        const tokenId = Utils.get(this.state, "moonpayInfo.tokenId"); //"112457658586931934152042554105038651850087300550240488280277587652331018649601";
        const apiKey = Utils.get(this.state, "moonpayInfo.apiKey"); //"pk_test_DsEcfo3q6iLBMIwpgpfldoOUcQE5nxGu";
        const sig = Utils.get(this.state, "moonpayInfo.sig"); //"IL%2BSQal4xSAbjGc4vqkUX6mRaqLSkaqhWHodeaglCaE%3D";
        const walletId = Utils.get(this.state, "moonpayInfo.walletId"); //"IL%2BSQal4xSAbjGc4vqkUX6mRaqLSkaqhWHodeaglCaE%3D";
        let MoonPayUrl = "https://buy-sandbox.moonpay.com/nft?contractAddress=" + contractAddress + "&tokenId=" + tokenId + "&apiKey=" + apiKey + "&signature=" + sig;
        if (walletId) {
            MoonPayUrl += "&walletAddress=" + walletId
        }
        //console.log("MoonPayUrl", MoonPayUrl);
        const moonPayHeight = 700;
        return (
            <Div className="cardMoonPay" key={key}>
                {this.state.showMoonPayModal ?
                    <Modal
                        screen={this.props.screen}
                        onRequestClose={this.closeMoonpayModal.bind(this, 'showMoonPayModal')}
                        className="biddingModal"
                        color="purple"
                        title={this.state.selectedTab == 'vip' ? "Buy Guardian VIP Pass" : "Buy Elohim Afterparty Pass"}
                    >
                        <Div style={{ height: moonPayHeight, width: "100%", borderRadius: 12, overflow: "hidden", }}>
                            <iframe
                                allow="accelerometer; autoplay; camera; gyroscope; payment"
                                frameBorder="0"
                                height="100%"
                                src={MoonPayUrl}
                                width="100%"
                            />
                        </Div>
                    </Modal>
                    :
                    null
                }
                {this.state.showMoonPayListingModal ?
                    <Modal
                        screen={this.props.screen}
                        onRequestClose={this.closeMoonpayModal.bind(this, 'showMoonPayListingModal')}
                        className="biddingModal"
                        color="purple"
                        title={this.state.selectedTab == 'vip' ? "Buy Guardian VIP Pass" : "Buy Elohim Afterparty Pass"}
                    >
                        <Div style={{ height: moonPayHeight, width: "100%", borderRadius: 12, overflow: "hidden", }}>
                            <Div>You've successfully bought your pass! You can see it by visiting your profile or take a look here:</Div>
                            <a href={`/soin`}>Listing {this.state.listingId}</a>
                        </Div>
                    </Modal>
                    :
                    null
                }

            </Div>
        );
    }

    renderCard_ghost(key, data) {
        if (!this.state.cardsDict) {
            return null;
        }
        //console.log("this.state.cardsDict", this.state.cardsDict);
        const cardStyle = Utils.get(data, "props.style", { width: "100%" });
        const url = Utils.get(data, "props.url");
        const cardId = Utils.get(data, "props.cardId");
        // TODO: getCss
        const css = Utils.get(data, "props.css");
        if (typeof css == 'object') {
            for (var cssIdx in css) {
                cardStyle[cssIdx] = css[cssIdx];
            }
        }
        let cardRender = <Div></Div>;
        if (cardId in this.state.cardsDict) {
            const curCard = this.state.cardsDict[cardId];
            const curCardDeepCopy = Utils.deepCopy(curCard);

            let originalCss = curCardDeepCopy.props.css;
            let newCss = css;

            curCardDeepCopy.props.css = { ...originalCss, ...newCss };

            const cardType = "renderCard_" + curCardDeepCopy['type'];
            cardRender = this[cardType](key, curCardDeepCopy);
        }
        return cardRender;
    }

    renderCard_input(key, data) {
        const num = 1;
        const formName = Utils.get(data, "props.form_name", 'form1');
        const field = formName + '_' + Utils.get(data, "props.name");
        const inputType = Utils.get(data, "props.inputType", "placeholderLabel");
        const cardStyle = this.getCss(data);
        const inputStyle = this.getCss(data, "cssInput");
        const placeholderLabelStyle = this.getCss(data, "cssPlaceholderLabelInput");
        const placeholder = Utils.get(data, "props.placeholder");
        const value = Utils.get(this.state, field, '');
        const maxLength = parseInt(Utils.get(data, "props.maxLength", 350));
        const cardId = Utils.get(data, "id", null);

        const isTextArea = Utils.get(data, "props.isTextArea") == "true";
        const postId = Utils.get(data, "props.postId");
        const cssId = Utils.get(data, "props.cssId");
        const inputId = "inp_"+this.state.postId+"_"+cardId;

        return (
            <Div className={`InputCard ${cardId}`} style={cardStyle} key={key}>
                {isTextArea ?
                    <Textarea
                        id={inputId}
                        value={value}
                        style={inputStyle}
                        onChange={this.changeVal.bind(this, field)}
                        placeholder={placeholder}
                        maxLength={maxLength}
                    />
                    :
                    <Input
                        id={inputId}
                        style={inputStyle}
                        placeholderLabelStyle={placeholderLabelStyle}
                        inputType={inputType}
                        value={value}
                        onChange={this.changeVal.bind(this, field)}
                        placeholder={placeholder}
                        maxLength={maxLength}
                    />
                }

            </Div>
        );

    }

    renderCard_checkbox(key, data) {
        const st = this.state;

        const num = 1;
        const formName = Utils.get(data, "props.form_name", 'form1');
        const field = formName + '_' + Utils.get(data, "props.name");
        const inputType = Utils.get(data, "props.inputType", "placeholderLabel");
        const cardStyle = this.getCss(data);
        let checkboxStyle = this.getCss(data, "props.checkboxStyle", null);

        cardStyle.width = "auto";
        checkboxStyle.width = 20;
        //console.log("CHECKBOX render", field, "=", Utils.get(st, field, false));

        return (
            <Div style={cardStyle} key={key}>
                <Checkbox
                    style={checkboxStyle}
                    checked={Utils.get(st, field, false)}
                    onClick={this.changeCheckVal.bind(this, field)}
                    placeholder={Utils.get(data, "props.placeholder")}
                />
            </Div>
        );

    }

    renderCard_carousel(key, data) {
        const pr = this.props;

        const cardStyle = this.getCss(data);
        const className = Utils.get(data, "props.className", "CarouselCard");

        // for (var i = 0; i < userItems.length; i++) {
        //     let userItem = userItems[i];
        //     let userItemImage = {
        //         borderRadius: 500,
        //         height: 100,
        //         width: 100,
        //         // backgroundImage: `url(${userItem.artist_image_url})`,
        //         backgroundImage: `url("https://pbs.twimg.com/profile_images/1478367769187926016/0QSuatcL_400x400.jpg")`,
        //         backgroundSize: "cover",
        //         backgroundPosition: "center",
        //         marginBottom: 15,
        //     };
        //
        //     carouselItems.push(
        //         <Div
        //             key={`${i}-myItem`}
        //             className={`carouselItem id-${userItem.id}`}
        //             style={{...sty.carouselItemContainer, ...StylesPlatform.cursorPointer}}
        //             onClick={this.selectCarouselItem.bind(this, userItem, i)}
        //         >
        //             <Div
        //                 style={userItemImage}
        //             >
        //                 {i === st.itemSelected ?
        //                 // {userItem.id === st.itemSelected ?
        //                     <Div className="selectedCheck" style={sty.selectedCheck}>
        //                         √
        //                     </Div>
        //                     :
        //                     null
        //                 }
        //             </Div>
        //             <Div className="artistName" style={sty.artistName}>
        //                 {userItem.artist_name}
        //             </Div>
        //         </Div>
        //     );
        // }

        return (
            <Div className={`${className} carouselAnimation`} style={cardStyle} key={key}>
                <Carousel
                    screen={pr.screen}
                    items={pr.user.items}
                    imagePath="image_url"
                    size={150}
                    carouselItemStyle={null}
                    style={{ background: "none" }}
                    gradientStyle={{ background: "none" }}
                    itemClick={this.itemClick.bind(this)}
                    // hideArrows
                    isAnimated
                >
                    {/* {carouselItems} */}
                </Carousel>
            </Div>
        );
    }

    emptyFunction() {
        console.log("emptyFunction >>>>");
    }

    renderCard_emailphoneverification(key, data) {
        const pr = this.props;
        const st = this.state;

        const isEmail = Utils.get(data, "props.isEmail") == "true";
        const showTokenBadges = Utils.get(data, "props.showTokenBadges") == "true";
        const startState = Utils.get(data, "props.startState");

        const cardStyle = this.getCss(data);

        if (!pr.userLoaded) {
            return <Spinner />;
        }

        return (
            <Div className="renderCardEmailPhoneVerification" style={cardStyle} key={key}>
                <EmailPhoneVerification
                    showTokenBadges={showTokenBadges}
                    startState={startState}
                    screen={pr.screen}
                    history={pr.history}
                    user={pr.user}
                    userLoaded={pr.userLoaded}
                    setUser={pr.setUser ? pr.setUser.bind(this) : this.emptyFunction.bind(this)}
                    continueFunction="hideContinueButton"
                    hideAfterVerified
                />
            </Div>
        );
    }

    renderCard_calculated(key, data) {
        const cardStyle = this.getCss(data);
        const calculated = this.setCalculatedValue()
        return (
            <Div className="CalculatedCard" style={cardStyle} key={key}>
                {calculated}
            </Div>
        );
    }

    // NOTE: parameters for hidden fields are set in the state at load time. That's why there is no render here.
    renderCard_hidden(key, data) {
        return null;
    }
    // NOTE: parameters for field cards are set in the state at load time. That's why there is no render here.
    renderCard_field(key, data) {
        return null;
    }


    renderCard_logo(key, data) {
        const pr = this.props;

        const logo = Utils.get(data, "props.logo");
        const width = Utils.get(data, "props.width", 244);
        const disableClick = Utils.get(data, "props.disableClick");

        const cardStyle = this.getCss(data);
        const footerStyle = this.getCss(data, "cssFooter");

        return (
            <Div style={cardStyle} key={key}>
                <AfterpartyLogo
                    screen={pr.screen}
                    history={pr.history}
                    logo={logo}
                    width={parseInt(width)}
                    disableClick={disableClick}
                />
            </Div>
        );
    }

    renderCard_panelqa(key, data) {
        const pr = this.props;
        const st = this.state;

        const title = Utils.get(data, "props.title");
        const name = Utils.get(data, "props.name");
        const enabled = Utils.get(data, "props.enabled");
        const type = Utils.get(data, "props.type", "show"); // types = show and modal
        if (enabled === "false") {
            return null;
        }
        const description = Utils.get(data, "props.description");
        const qaReference = Utils.get(data, "props.name");

        const cardStyle = this.getCss(data);

        const panelQaStyle = {
            backgroundColor: "white",
            color: "#7B7B7B",
            paddingTop: 0,
            paddingRight: 120,
            paddingBottom: 15,
            paddingLeft: 35,
            fontSize: 21,
            fontWeight: 400,
            borderBottomLeftRadius: 14,
            borderBottomRightRadius: 14,
        }
        //                    "form1_mine": "show",

        //let clickQa = this.openClosePanelQaModal.bind(this, qaReference, true);
        let clickQa = () => {
            let showName = "form1_" + name;
            let tempState = {};
            if (this.state[showName] == "show") {
                tempState[showName] = "hide";
            } else {
                tempState[showName] = "show";
            }
            this.setState(tempState);
        };
        return (
            <Div style={{ ...cardStyle, ...panelQaStyle }} key={key}>
                <Div style={{ ...{ textDecoration: "underline" }, ...StylesPlatform.cursorPointer }} onClick={clickQa}>
                    {title}
                </Div>

                {type == 'modal' && st[qaReference] ?
                    <Modal
                        screen={pr.screen}
                        title={title}
                        onRequestClose={this.openClosePanelQaModal.bind(this, qaReference, false)}
                        className="panelQaModal"
                        style={{ alignItems: "left" }}
                        color="indigo"
                    // hideCloseButton={st.successRedirectUrl && st.successRedirectUrl.length > 0}
                    >
                        {this.plainTextToRichTextArray(description, false, key)}
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    renderCard_panelqatopic(key, data) {
        const pr = this.props;

        const title = Utils.get(data, "props.title");
        const description = Utils.get(data, "props.description");
        const group = Utils.get(data, "props.group");

        const cardStyle = this.getCss(data);

        return (
            <Div style={cardStyle} key={key} onClick={this.toggleQaPanel.bind(this, group)}>
                <PanelQa
                    screen={pr.screen}
                    history={pr.history}
                    title={title}
                    description={description}
                />
            </Div>
        );
    }

    renderCard_footer(key, data) {
        const pr = this.props;

        const num = 1;
        const formName = Utils.get(data, "props.form_name", 'form1');
        const field = formName + '_' + Utils.get(data, "props.name");
        const isBorderSeparator = Utils.get(data, "props.border", true);
        const cardStyle = this.getCss(data);
        const footerStyle = this.getCss(data, "cssFooter");

        return (
            <Div style={cardStyle} key={key}>
                <SplashFooter
                    screen={pr.screen}
                    style={footerStyle}
                    footerColor={Utils.get(data, "props.footerColor")}
                    history={pr.history}
                // isFooterBorder={isBorderSeparator}
                />
            </Div>
        );
    }

    renderCard_socialIcons(key, data) {
        const cardStyle = this.getCss(data);
        const size = parseInt(Utils.get(data, "props.size", 40));

        return (
            <Div style={cardStyle} key={key}>
                <SplashSocialMediaIcons
                    screen={this.props.screen}
                    size={size}
                />
            </Div>
        );
    }

    renderCard_audiochip(key, data, loopState) {
        const st = this.state;

        const cardStyle = this.getCss(data);
        // const size = parseInt(Utils.get(data, "props.size", 40));
        let audioUrl = Utils.get(data, "props.audioUrl");
        const artistId = Utils.get(data, "props.artistId");
        const hideAudioChipMenu = Utils.get(data, "props.hideAudioChipMenu");
        const audioChipColor = Utils.get(data, "props.audioChipColor");
        const format = Utils.get(data, "props.format");
        const artistsDict = st['field^artistsDict'];
        const artist = Utils.get(artistsDict, artistId);

        // For items, you need to inject the param value for each item into the props value
        const param = Utils.get(data, "props.param");
        if (!Utils.empty(param)) {
            const paramVal = Utils.get(loopState, param);
            //console.log("AUDIOPARAM", param, paramVal)
            //console.log("INJECTED FIELD CONTAINER!", key, data, data['id'], paramVal);
            data['props']['value'] = paramVal;
            audioUrl = paramVal;
        }

        return (
            <Div className="audiochip-container" style={cardStyle} key={key}>
                <AudioChip
                    localAudioPlayer
                    src={audioUrl}
                    backgroundImage={Utils.get(artist, "audio_chip_background_image_url")}
                    avatar={Utils.get(artist, "image_url")}
                    hideAudioChipMenu={hideAudioChipMenu == "true" ? true : false}
                    audioChipColor={audioChipColor}
                    format={format}
                />
            </Div>
        )
    }

    renderCard_audioshoutout(key, data) {
        const st = this.state;

        const cardStyle = this.getCss(data);
        // const size = parseInt(Utils.get(data, "props.size", 40));

        const shoutout = st['field^shoutout'];

        const artistId = Utils.get(data, "props.artistId");
        const artistsDict = st['field^artistsDict'];
        const artist = Utils.get(artistsDict, artistId);

        return (
            <Div style={cardStyle} key={key}>
                <AudioShoutout
                    user={pr.user}
                    artist={artist}
                    shoutout={shoutout}
                    screen={pr.screen}
                />
            </Div>
        )
    }

    renderCard_sociallinks(key, data) {
        const cardStyle = this.getCss(data);
        const size = parseInt(Utils.get(data, "props.size", 40));
        const type = Utils.get(data, "props.type");

        const artistCustomer = Utils.get(this.state, 'field^artist_customer');

        return (
            <Div style={cardStyle} key={key}>
                <SocialLinks
                    artistCustomer={artistCustomer}
                    screen={this.props.screen}
                    type={type}
                />
            </Div>
        );
    }

    renderCard_svg(key, data) {
        const cardStyle = this.getCss(data);
        const body = Utils.get(data, "props.body", 40);

        return (
            <Div style={cardStyle} key={key}>
                <svg>
                    <path d={body} />
                </svg>
            </Div>
        );
    }



    renderCard_include(key, data) {
        const cardStyle = this.getCss(data);
        const postId = parseInt(Utils.get(data, "props.postId"));
        if (!postId) {
            ll._("Included post card contains no postId.", "red");
            return null;
        }
        const includedPostCards = Utils.get(this.state, "includedPosts." + postId);
        if (!includedPostCards) {
            ll._("Included post ID " + postId + " not found.", "red");
            return null;
        }
        let incCards = Utils.get(includedPostCards, "json_data.cards");
        const cardList = this.renderCardLoop(incCards, "includedCards");

        return (
            <Div className="cmsIncludeCard" style={cardStyle} key={key}>
                {cardList}
            </Div>
        );
    }


    renderCardLoop(cards, title, loopState) {
        ll.silent = 1; //Utils.getRequest("silent", 1);
        if (!cards) {
            //console.log("No cards "+title+" to render");
            return;
        }

        // NOTE: loopState allows to overwrite the original state when using renderCardLoop for a partial post
        loopState = loopState ? loopState : this.state;

        let cardList = [];
        let containerChildren = [[], [], [], [], [], [], [], [], [], [], [], []];
        let insideContainer = [false, false, false, false, false, false, false, false, false];
        let containerLevel = 0;
        let lastIndentLevel = 0;
        let nonLevel0Containers = 0;
        ll._("__Start render of " + cards.length + " cards: " + title, "green");
        this.htmlStr = '';
        for (var idx in cards) {
            const key = `card-${title}-key-${idx}`;
            const curCard = cards[idx];
            const cardType = "renderCard_" + curCard['type'];
            const cardTitle = curCard['title'];
            if (Utils.get(curCard, "props.enabled") == "false") {
                ll._("Card " + cardTitle + " disabled", "red");
                continue;
            }

            // Check if render method for this type of card exists
            if (!this[cardType]) {
                ll._("Card type not found: " + cardType, "red")
                continue;
            }
            ll._("Process card: " + cardTitle + " (" + curCard['type'] + ")", "yellow");

            if (!insideContainer[0] && curCard['type'] != "container") {
                ll._("Top level card. Push to cardlist", "blue");
                // Outside container items are typically parameter setting cards
                cardList.push(this["renderCard_" + curCard['type']](key, curCard, loopState));
                continue;
            }
            if (this.state.renderHtml) {
                //this.htmlStr += '<div>'+curCard['type']+'</div>';
            }

            if (curCard['type'] == "container") {
                const cardLevel = Utils.get(curCard, "props.level", 0);
                containerLevel = cardLevel;
                ll._("Container level: " + containerLevel);
                //console.log("Container level: ",cardLevel, " lastIndentLevel: ",  lastIndentLevel);
                if (cardLevel > 1) {
                    nonLevel0Containers++;
                }
                if (cardLevel != 0 && lastIndentLevel >= cardLevel) {
                    // This needs to roll up above current card level to parent of this card to start over
                    const startLevel = lastIndentLevel;
                    const finalLevel = cardLevel - 1;
                    ll._("Roll up cards " + (startLevel - finalLevel) + " level(s) from level " + lastIndentLevel + " to parent level " + (containerLevel - 1), "orange");
                    //console.log(insideContainer);
                    this.logStack(containerChildren);
                    // Push all cached cards as children of container
                    let containerBeingRolled, childrenToRollup, rollupRender;
                    for (var curLevel = startLevel; curLevel > finalLevel; curLevel--) {
                        ll._("curLevel", curLevel, "startLevel", startLevel, "finalLevel", finalLevel);
                        containerBeingRolled = insideContainer[curLevel];
                        childrenToRollup = containerChildren[curLevel];
                        rollupRender = this[cardType](key, containerBeingRolled, childrenToRollup, false, "column", loopState);
                        containerChildren[curLevel - 1].push(rollupRender);
                        // Reset rolled up children
                        containerChildren[curLevel] = [];
                    }

                    //console.log("Reset children list");

                    this.logStack(containerChildren);
                    // Set current card as new container
                } else if (cardLevel == 0 && lastIndentLevel > cardLevel) {
                    ll._("Push all leftover cached cards to last root node");
                    containerChildren[0].push(this[cardType](insideContainer[lastIndentLevel], containerChildren[lastIndentLevel], false, "column"));
                    containerChildren[lastIndentLevel] = [];
                    cardList.push(this[cardType](key, insideContainer[containerLevel], containerChildren[containerLevel], false, "row"));
                    containerChildren[containerLevel] = [];
                }
                lastIndentLevel = cardLevel
                insideContainer[containerLevel] = curCard;
                ll._("  Set lastIndentLevel to " + lastIndentLevel + " and set inside card for level " + containerLevel);
            } else {
                ll._("  Push card onto containerLevel " + containerLevel + " stack");
                containerChildren[containerLevel].push(this[cardType](key, curCard, loopState));
            }
        }
        //console.log("containerChildren before", containerChildren, insideContainer);
        // Push any leftover containers
        if (containerChildren.length - 1 > 0) {
            for (let i = containerChildren.length - 1; i > 0; i--) {
                const key = `card-${title}-key-leftover-${i}`;
                //console.log("i", i, i-1);
                if (containerChildren[i].length > 0) {
                    ll._("Found " + containerChildren[i].length + " rollup children for idx ", i,);
                    containerChildren[i - 1].push(this["renderCard_container"](key, insideContainer[i], containerChildren[i], undefined, undefined, loopState));
                }
                containerChildren[i] = [];
            }
        }
        const finalKey = `final-${title}-key`;
        cardList.push(this["renderCard_container"](finalKey, insideContainer[0], containerChildren[0], undefined, undefined, loopState));
        if (nonLevel0Containers == 0) {
            ll._("ERROR: All containers are level 0 (root) containers. At least 1 non-empty container required for proper rendering.", "red");
        }
        ll._("__Done render cards", "green", cardList);
        ll.silent = 1; //false;
        return cardList;
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        if (st.isLoading) {
            return null;
        } else if (!st.pageInfo) {
            return (
                <PageNotFound
                    history={pr.history}
                    screen={pr.screen}
                    isRedirect
                />
            )
        }

        //const stage = 'marketing';
        const stage = Utils.isDemoMode() ? "sale" : st.stage;
        const containerStyle = 'container_demo';

        const container = StyleUtils.getMediaStyle(containerStyle, sty[containerStyle], sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        //console.log("POST", this.state.pageInfo);
        let cards = Utils.get(st, "pageInfo.cards", []);
        if (st.desktopCards && st.mobileCards) {
            if (pr.screen.mobile) {
                cards = st.mobileCards;
            } else {
                cards = st.desktopCards;
            }
        }
        const cardList = this.renderCardLoop(cards, "Main");
        let ipCards = Utils.get(st.injectedPosts, "31.json_data.cards");
        //console.log("ipCards", ipCards);
        const cardList2 = this.renderCardLoop(ipCards, "ipCards");
        const postId = Utils.get(pr, "postId", '-');
        if (st.renderHtml) {
            return (
                <div
                    dangerouslySetInnerHTML={{ __html: this.htmlStr }}
                />
            );
        }

        return (
            <Div data-post-id={postId}>
                <DebugBox
                    title="Post"
                    show={this.state}
                />
                {cardList}
                {false && st.showSampleFormModal ?
                    <Modal
                        screen={pr.screen}
                        onRequestClose={this.onRequestCloseSampleFormModal.bind(this)}
                        className="biddingModal"
                        color="indigo"
                        title={st.successTitle}
                        hideCloseButton={st.successRedirectUrl && st.successRedirectUrl.length > 0}
                    >
                        {cardList2}
                    </Modal>
                    :
                    null
                }

                {st.showRegisterGenericModal ?
                    <Modal
                        onRequestClose={this.closeRegisterGenericModal.bind(this)}
                        className="learnMoreModal"
                        screen={pr.screen}
                    >
                        <WebappLoginRegister
                            isLogin={false}
                            screen={pr.screen}
                            user={pr.user}
                            history={pr.history}
                            style={{ minHeight: 0, width: "100%", maxWidth: 400 }}
                            setUser={pr.setUser.bind(this)}
                            onLoginPhone={this.onLoginPhone.bind(this)}
                            onLoginEmail={this.onLoginEmail.bind(this, false, true)}
                            onSignUpPhone={this.onSignUpPhone.bind(this)}
                            onConfirmVerificationCodeLogin={this.onConfirmVerificationCodeLogin.bind(this)}
                            onConfirmVerificationCodeSignUp={this.onConfirmVerificationCodeSignUp.bind(this)}
                            isModalForGoogle
                        />
                    </Modal>
                    :
                    null
                }
            </Div>
        );
    }

    styles = {
        cardContainer: {
            backgroundSize: "cover",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            width: "100%",
            // overflow:"hidden", // NOTE: CONNER OVERFLOW HIDDEN
            //height:852,
            display: "flex",
        },
        leaderboardTop3Container: {
            flex: 1,
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
        },
        leaderboardTop3UserContainer: {
            flex: 1,
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            maxWidth: "30%",
        },
        leaderboardTop3Photo: {
            width: 64,
            height: 64,
            background: "blue",
            borderRadius: 32,
            overflow: "hidden",
            position: "relative",
        },
        avatarNum: {
            position: "absolute",
            bottom: -10,
            background: Colors.purpleDark,
            borderRadius: 100,
            padding: "3px 10px",
            textAlign: "center",
            boxShadow: "0px 1.57022px 13.3469px #DC4D6F",
            textShadow: "0px 1.57022px 6.28087px #FFAD9C",
            fontSize: 19,
            left: "50%",
            transform: "translate(-50%, 0)"
        },
        leaderboardElement: {
            maxWidth: 692,
            width: "100%",
            fontFamily: "Nyata",
            color: "white",
        },
        leaderboardHeader: {
            width: "100%",
            marginBottom: 10,
        },
        leaderboardHeader_md: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        leaderboardFirstPlace: {
            height: 181,
            backgroundColor: Colors.indigoLight,
            borderRadius: 36,
            display: "flex",
            alignItems: "center",
            padding: 17,
            boxShadow: "0px 4px 85px rgba(246, 113, 78, 0.6), inset 0px 0px 18.7151px rgba(237, 107, 100, 0.4)",
            border: "2px solid #E8B365",
            marginBottom: 12,
        },
        leaderboardFirstPlace_md: {
            height: 94,
            borderRadius: 18,
            padding: "8px 17px",
            boxShadow: "none",
        },
        leaderboardSecondPlace: {
            height: 162,
            backgroundColor: Colors.indigoLight,
            borderRadius: 32,
            display: "flex",
            alignItems: "center",
            padding: 17,
            boxShadow: "0px 4px 85px rgba(246, 113, 78, 0.6), inset 0px 0px 18.7151px rgba(237, 107, 100, 0.4)",
            border: "2px solid #EE6E62",
            marginBottom: 12,
        },
        leaderboardSecondPlace_md: {
            height: 94,
            borderRadius: 18,
            padding: "8px 17px",
            boxShadow: "none",
        },
        leaderboardThirdPlace: {
            height: 162,
            backgroundColor: Colors.indigoLight,
            borderRadius: 32,
            display: "flex",
            alignItems: "center",
            padding: 17,
            boxShadow: "0px 4px 85px rgba(246, 113, 78, 0.6), inset 0px 0px 18.7151px rgba(237, 107, 100, 0.4)",
            border: "2px solid #C92D7A",
            marginBottom: 12,
        },
        leaderboardThirdPlace_md: {
            height: 94,
            borderRadius: 18,
            padding: "8px 17px",
            boxShadow: "none",
        },
        leaderboardSecondPlaceContainer: {
            maxWidth: 619,
            marginLeft: "auto",
            marginRight: "auto",
        },
        leaderboardThirdPlaceContainer: {
            maxWidth: 619,
            marginLeft: "auto",
            marginRight: "auto",
        },
        gradientTrophyBackground: {
            position: "relative",
            background: "linear-gradient(180deg, rgba(93, 57, 172, 0.2) 0%, rgba(253, 115, 73, 0.2) 100%)",
            borderRadius: 22,
            width: 125,
            height: 146,
        },
        gradientTrophyBackground_md: {
            width: 65,
            height: 75,
            borderRadius: 12,
        },
        trophyIcon: {
            position: "absolute",
            width: 240,
            top: -94,
            left: -54,
        },
        trophyIcon_md: {
            width: 120,
            top: -50,
            left: -25,
        },
        trophyIconSmaller: {
            position: "absolute",
            width: 200,
            top: -72,
            left: -40,
        },
        trophyIconSmaller_md: {
            width: 125,
            top: -52,
            left: -26,
        },
        rankPointsContainer: {
            marginLeft: "auto",
            textAlign: "center",
        },
        rankText: {
            background: "linear-gradient(180deg, #EE9462 0%, #C92D7A 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textFillColor: "transparent",
            backgroundClip: "text",
            textShadow: "0px 0.341359px 0.682717px rgba(0, 0, 0, 0.1)",
            fontSize: 20,
        },
        rankText_md: {
            fontSize: 16,
        },
        rankPoints: {
            fontSize: 50,
            background: "linear-gradient(180deg, #EE9462 0%, #C92D7A 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textFillColor: "transparent",
            backgroundClip: "text",
            textShadow: "0px 0.341359px 0.682717px rgba(0, 0, 0, 0.1)",
        },
        rankPoints_md: {
            fontSize: 32,
        },
        userPoints: {
            border: "2px solid #69347A",
            borderRadius: 14,
            padding: "6px 13px",
            textAlign: "center",
            marginTop: 16,
            fontSize: 25,
            textShadow: "0px 1.99931px 7.99723px #DD60FE",
            width: "fit-content",
        },
        userPoints_md: {
            fontSize: 16,
            marginTop: 8,
            padding: "4px 8px",
            borderRadius: 8,
        },
        userLeaderboardContainer: {
            maxWidth: 500,
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "20px 28px",
            // background: "linear-gradient(135deg, #C92D7A 0%, #EE6E62 100%)",
            // background: "linear-gradient(#C92D7A, #EE6E62) padding-box, linear-gradient(135deg, #C92D7A, #EE6E62) border-box",
            borderRadius: 33,
            border: `2px solid ${Colors.magenta}`,
            backgroundColor: Colors.indigoLight,
            //boxSizing: "border-box",
            marginBottom: 12,
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: "inset 0px 0px 15.7894px rgba(237, 107, 100, 0.4)",
        },
        userLeaderboardContainer_md: {
            borderRadius: 20,
            padding: 20,
            paddingBottom: 15,
        },
        otherLeadersContainer: {
            maxWidth: 500,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        otherLeadersContainer_md: {
        },
        leaderboardRow: {
            display: "flex",
            justifyContent: "",
            alignItems: "center",
            width: "100%",
            marginBottom: 12,
            padding: "20px 24px",
            backgroundColor: Colors.indigoLight,
            borderRadius: 33,
            border: "2px solid #888193",
        },
        leaderboardRow_md: {
            padding: "8px 17px",
            borderRadius: 20,
        },
        usernameText: {
            fontSize: 20,
            fontWeight: 400,
        },
        usernameText_md: {
        },
        quoteTextContainer: {
            display: "flex",
        },
        fanMessage: {
            fontWeight: 300,
            fontSize: 20,
        },
        fanMessage_md: {
            fontSize: 12,
        },
        largeQuoteContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        largeQuoteContainer_md: {
            marginLeft: 15,
        },
        usernameTextTop: {
            fontSize: 20,
            fontWeight: 400,
            color: "#BABABA",
            paddingLeft: 55,
            marginBottom: 4,
        },
        usernameTextTop_md: {
            paddingLeft: 0,
            fontSize: 15,
        },
        pinkDotQuoteLeft: {
            fontSize: 68,
            height: 36,
            width: 56,
            borderRadius: 50,
            color: Colors.magenta,
            paddingLeft: 12,
            paddingRight: 12,
            flexShrink: 0,
            fontWeight: 400,
            marginTop: -10,
        },
        pinkDotQuoteRight: {
            fontSize: 68,
            height: 36,
            width: 56,
            borderRadius: 50,
            color: Colors.magenta,
            paddingLeft: 12,
            paddingRight: 12,
            flexShrink: 0,
            marginRight: 30,
            marginTop: "auto",
            fontWeight: 400,
        },
        usernamePointsContainer: {
            marginLeft: 37,
        },
        usernamePointsContainer_md: {
            marginLeft: 15,
        },
        leaderPointsInRow: {
            fontSize: 28,
            fontWeight: 400,
            marginLeft: "auto",
        },
        leaderPointsInRow_md: {
            fontSize: 21,
        },
        leaderPoints: {
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: 32,
            fontWeight: 500,
            padding: "7px 15px",
            background: "linear-gradient(90deg, #C92D7A 0%, #EE6E62 100%)",
            borderRadius: 100,
            textShadow: "0px 2.08515px 8.3406px #DD60FE",
        },
        leaderPoints_md: {
            padding: "5px 8px",
            fontSize: 16,
        },
        selectStyle: {
            fontFamily: "Nyata",
            fontSize: 16,
            borderRadius: 8,
            padding: 8,
            minWidth: 80,
            backgroundColor: Colors.indigo,
            color: "white",
            borderColor: "#444",
        },
        igPostsContainer: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
            marginLeft: 10,
            marginRight: 10,
            textAlign: "left",
            fontSize: 16,
            // textOverflow: "ellipsis",
            // overflow: "hidden",
            // display: "block",
            // whiteSpace: "nowrap",
            // display: "-webkit-box",
            // WebkitLineClamp: 3,
            // WebkitBoxOrient: "vertical",
        },
        mintButton: {
            background: "rgba(200,0,0,0.5)",
            borderRadius: 12,
            padding: 7,
            //cursor: pointer;
        },
        igImageContainer: {
            width: 276,
            height: 276,
            overflow: "hidden",
        },
        igImageCaptionContainer: {
            padding: 8,
            display: "flex",
            flexDirection: "row",
            color: "white",
            marginTop: 10,
        },
        igImageCaptionTitle: {
            flex: 1,
            fontSize: 14,
        },
        igGalleryStyle: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            gap: "35px 20px",
            flexWrap: "wrap",
            backgroundColor: "#413750",
            padding: 20,
            borderRadius: 15,
            maxWidth: 974,
        },
        igIcon: {
            display: "flex",
            justifyContent: "end",
            paddingRight: 12,
            paddingTop: 10,
        },
        igPostStyle: {
            maxWidth: 300,
            overflow: "hidden",
            border: `1px solid ${Colors.magenta}`,
            borderRadius: 16,
            padding: 10,
            width: "100%",
        },
        igImageContainerStyle: {
            borderRadius: 15,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 8,
        },
        igImageContainerOutside: {
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
            position: "relative"
        },
        venmoSafariMessage: {
            textAlign: "center",
            display: "inline-block",
            alignItems: "center",
            marginBottom: 20,
        },
        artistGalleryRow: {
            display: "flex",
            gap: 16,
            fontSize: 21,
            scrollSnapType: "x mandatory",
        },
        artistGalleryRow_md: {
            fontSize: 16,
        },
        artistHorizontalScroll: {
            overflowX: "scroll",
            overflowY: "hidden",
        },
        artistWrap: {
            flexWrap: "wrap",
        },
        artistGalleryCard: {
            fontFamily: "Nyata",
            fontWeight: 400,
            // padding: 16,
            // background: "#271D37",
            boxShadow: "0px 7px 24px rgba(7, 32, 47, 0.12)",
            borderRadius: 16,
            // textAlign: "center",
            scrollSnapStop: "always",
            scrollSnapAlign: "start",
        },
        artistGalleryCard_md: {
            borderRadius: 12,
            padding: 12,
        },
        artistGalleryImage: {
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderRadius: 12,
            marginBottom: 16,
            padding: 4,
            marginTop: "auto",
            display: "flex",
        },
        liveThisWeek: {
            backgroundColor: Colors.magenta,
            borderRadius: 100,
            border: "1px solid white",
            fontWeight: 400,
            fontSize: 16,
            padding: 2,
            marginTop: "auto",
            display: "flex",
            justifyContent: "center",
            width: "100%",
        },
        stripeAppleSubmitButton: {
            background: "black",
            backgroundImage: "url(/images/apple_pay_white.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: 140,
            height: 58,
            border: "1px solid white",
            flex: 1,
            backgroundSize: "68px 32px",
            borderRadius: 12,
        },
        stripeGooglePaySubmitButton: {
            background: "black",
            backgroundImage: "url(/images/google_pay_white.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: 140,
            height: 58,
            border: "1px solid white",
            flex: 1,
            backgroundSize: "80px 32px",
            borderRadius: 12,
        },
        artistGalleryFollowButton: {
            // maxWidth: 140,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 16,
            width: "100%",
        },
    };
}
