import React from 'react';

import Utils from '../modules/Utils';

import Div from '../components/Div';
import Button from '../elements/Button';

export default class DebugBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMode: this.props.mode ? this.props.mode : 'brief',
        };
    }

    clearCookies() {
        for (var i = 0; i < 5000; i++) {
            Utils.deleteCookie("s-" + i);
        }
    }

    render() {
        const pr = this.props;

        const debug = Utils.getRequest("debug");
        const debugVar = Utils.getRequest("debugVar");
        let body = "";
        const showBox = debug == 11 || debug == 12 || pr.alwaysShow;

        const show = debugVar ? pr.show[debugVar] : pr.show;
        if(showBox) {
            if(show) {
                if(debug == 11) {
                    body = JSON.stringify(show, Utils.getCircularReplacer());
                } else {
                    body = JSON.stringify(show, Utils.getCircularReplacer(), 2);
                }
            }
        }
        return showBox ?
            <Div className="debugbox" style={this.styles.container}>
                {pr.title ?
                    <Div className="debugboxTitle" style={this.styles.title}>
                        {this.props.title}
                    </Div>
                    :
                    null
                }
                <Button
                    size="small"
                    onClick={this.clearCookies.bind(this)}
                >
                    Clear Cookies
                </Button>
                <pre style={{fontSize:12,}}>
                    {body}
                </pre>
            </Div>
            :
            null ;
    }

    styles = {
        container: {
            width: '90%',
            padding: "0px 20px",
            background: "#FFF9D6",
            maxHeight:150,
            overflow: "scroll",
            color: "black",
        },
        title: {
            color: "black",
            fontSize:18,
        },
    };
}
